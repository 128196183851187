<!-- <h2 mat-dialog-title>Terms & Conditions</h2> -->
<button class="close" mat-button mat-dialog-close>X</button>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ngx-extended-pdf-viewer [src]="pdf" [height]="'100vh'" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true">
        </ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
</mat-dialog-content>
