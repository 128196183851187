import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-privacy-policy-modal",
  templateUrl: "./privacy-policy-modal.component.html",
  styleUrls: ["./privacy-policy-modal.component.scss"],
})
export class PrivacyPolicyModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PrivacyPolicyModalComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
