<section class="section-pages">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5 mb-4">
        <img
          class="px-5 px-md-0"
          src="assets/images/casino-license-page.png"
          alt=""
        />
      </div>

      <div class="col-12 col-md-6 col-lg-7 pl-md-4 pl-lg-5">
        <div class="mb-3 pt-md-3 pages-logo">
          <img
            class="px-5 px-md-0"
            src="assets/images/casino-license-logo.svg"
            alt=""
          />
        </div>

        <p class="mb-0 mb-md-4">
          {{ "casinoLicense.text" | translate }}
        </p>
        <div class="btn-container">
          <a
            class="btn mb-0 mb-md-5"
            href="https://casinolicense.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ "casinoLicense.btn" | translate }}
            <span>
              {{ "casinoLicense.btnTwo" | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="row pb-5">
      <div class="col-12">
        <p class="pb-4">
          {{ "casinoLicense.textTwo" | translate }}
        </p>
        <p>
          {{ "casinoLicense.textThree" | translate }}
        </p>
        <br />
      </div>
    </div>
  </div>
</section>
