import { Component, OnInit } from "@angular/core";
import { HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  d = document;
  flat: any;

  constructor(public translate: TranslateService, public languageService: LanguageService) {
    this.translate.setDefaultLang(this.languageService.selectedLanguage);
    this.translate.use(this.languageService.selectedLanguage);
  }

  toogleLanguage(lang: any) {
    this.translate.use(lang.target.id);
    var target = lang.target || lang.srcElement || lang.currentTarget;
    var idAttr = target.attributes.id;
    var value = idAttr.nodeValue;

    this.translate.use(value);

    var cards = document.querySelectorAll(".flip-card-two");
    if (value === "en") {
      this.languageService.selectedLanguage = "en";
      if (cards) {
        for (var i = 0; i < cards.length; ++i) {
          cards[i].classList.remove('es');
        }
      }
    }

    if (value === "es") {
      this.languageService.selectedLanguage = "es";

      if (cards) {
        for (var i = 0; i < cards.length; ++i) {
          cards[i].classList.add('es');
        }

      }

    }

    if (value === "pt") {
      this.languageService.selectedLanguage = "pt";

      if (cards) {
        for (var i = 0; i < cards.length; ++i) {
          cards[i].classList.add('pt');
        }

      }

    }

    let menu = document.getElementById("myDropdown");
    menu.classList.remove("show");
  }

  ngOnInit() {
  }

  @HostListener("window:click", ["$event.target"])
  onClick(targetElement: HTMLElement) {
    console.log(targetElement);

    if (!targetElement.classList.contains("mySidenav") && !targetElement.classList.contains('menu-btn')){
      document.getElementById("mySidenav").classList.remove("open");
    }

    if (targetElement.classList.contains("dropbtn") || targetElement.classList.contains("img-flat")) {
    } else {
      let menu = document.getElementById("myDropdown");
      menu.classList.remove("show");
    }
  }

  openNav() {
    document.getElementById("mySidenav").classList.add("open");
  }

  closeNav() {
    document.getElementById("mySidenav").classList.remove("open");
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const header = document.getElementById("header");
    const menu = document.getElementById("landing-setup");
    const sticky = 100;

    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
      menu.classList.add("sticky-menu")
    } else {
      header.classList.remove("sticky");
      menu.classList.remove("sticky-menu")
    }
  }

}

