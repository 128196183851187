import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Payments } from './../interfaces/payments';
import { Observable } from 'rxjs';
import { DataRowOutlet } from '@angular/cdk/table';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  public api = 'https://api.rgt.com';
  // public api = 'http://127.0.0.1:8005';
  names: string;
  email: string;

  constructor(private http: HttpClient) {}

  getNamesEmail(namesEmail) {
    this.names = namesEmail.names;
    this.email = namesEmail.email;
    console.log(namesEmail);
  }

  CreatePaymentSimple(payments) {
    payments.status = 'Unpaid';
    payments.status2 = 'Not Sent';
    payments.orderNumber = Number(payments.orderNo);

    return this.http.post(this.api + '/rgtpayment', payments, httpOptions).toPromise();
  }

  SendContactForm(data) {

    return this.http.post(this.api + '/send_contact', data, httpOptions).toPromise();
  }

  SendSetupForm(data) {

    console.log(data);

    return this.http.post(this.api + '/send_setup_form', data).toPromise();
  }

  /*CreatePayment(payments, preorder) {
    payments.status = 'Unpaid';
    payments.preorder = preorder;

    return this.http.post(this.api + '/rgtpayment', payments, httpOptions).toPromise();
  }

  InsertPreOrder(data, preorder) {
    let value = {
      names: data.names,
      email: data.email,
      preorder: preorder,
      status: "Not sent"
    };
    return this.http.post(this.api + '/preorder', value, httpOptions).toPromise();
  } */

}
