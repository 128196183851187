<h2 class="texts__title --manual tab-title mb-3">
  Name
</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  1. To update your Site Name go to Dashboard > Skin > Settings
</p>

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  2. Type your site new “Name”
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/skin/settings/name/name-one.png" alt=" subir name" />

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  3.Press "Update Changes" button in the upper right corner to apply changes and then refresh page to be able to see them.
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/skin/settings/logo/logo-two.png" alt="" />