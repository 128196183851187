<section class="headings --internal-pages py-5" style="height: auto;">
  <div class="headings__affiliate">
    <p class="headings__affiliate__text mb-3 mb-md-3 pt-4 pt-md-0">
      RGT Casino Affiliate Program
    </p>

    <p class="headings__affiliate__text" [innerHTML]="registerSuccess"></p>

    <mat-tab-group (selectedTabChange)="onSignup($event)" mat-align-tabs="center" *ngIf="registerSuccess == ''">
      <mat-tab label="Login">
        <!-- Login -->

        <form fxLayout="column" fxLayoutAlign="space-between center" [formGroup]="authForm" method="POST" id="loginForm" action="https://affiliates.rgt.com/login.php">
          <mat-form-field appearance="standard">
            <input matInput placeholder="Username" formControlName="userid" id="userid" name="userid">
          </mat-form-field>

          <mat-form-field appearance="standard">
            <input matInput type="password" placeholder="Password" formControlName="password" id="password" name="password">
          </mat-form-field>

          <button (click)="login()" class="btn" style="display: inline-block;">Login</button>

          <mat-error *ngIf="loginError != '' ">{{loginError}}</mat-error>

        </form>

      </mat-tab>
      <mat-tab label="Sign Up">

        <!-- Register -->

        <form fxLayout="column" fxLayoutAlign="space-between center" [formGroup]="registerForm">
          <mat-form-field appearance="standard">
            <input matInput placeholder="First Name" formControlName="first_name" id="first_name" name="first_name">
            <mat-error *ngIf="registerForm.get('first_name').invalid && registerForm.get('first_name').touched">Invalid Field</mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <input matInput placeholder="Last Name" formControlName="last_name" id="last_name" name="last_name">
            <mat-error *ngIf="registerForm.get('last_name').invalid && registerForm.get('last_name').touched">Invalid Field</mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <input matInput placeholder="Username" formControlName="username" id="username" name="username">
            <mat-error *ngIf="registerForm.get('username').invalid && registerForm.get('username').touched ">Invalid Field</mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <input matInput placeholder="Email" formControlName="email" id="email" name="email">
            <mat-error *ngIf="registerForm.get('email').invalid && registerForm.get('email').touched">Invalid Field</mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard">
            <input matInput type="password" placeholder="Password" formControlName="password" id="password" name="password">
            <mat-error *ngIf="registerForm.get('password').invalid && registerForm.get('password').invalid">Invalid Field</mat-error>
          </mat-form-field>

          <button (click)="register()" class="btn" style="display: inline-block;">Register</button>

          <mat-error *ngIf="registerError != '' ">{{registerError}}</mat-error>


        </form>

      </mat-tab>
    </mat-tab-group>
  </div>

</section>

<section class="section --affiliates">

  <div class="section__border-img --border-affi">
    <img class="--border-affi" src="assets/images/border-turnkey.png " alt=" " />
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="texts__title mb-2">
          Refer visitors to us and earn $298.50 for each qualified signup.
        </h2>
      </div>

      <div class="col-12 col-md-12 mt-lg-2">
        <p class="texts__paragraphs mb-1">
          Online casinos are multi-billion dollar industry and until now, there
          hasn't been a cost-effective way for people to start their own. We've
          made it super-simple for anybody to start their own online casino and
          sportsbook. With the amount of money that is being poured into
          internet businesses, this is the perfect time for you to promote the
          only "start your own casino " affiliate program.
        </p>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-md-7">
        <p class="texts__paragraphs mb-3">
          Online casinos present the perfect internet business opportunity (aka
          bizopp) for people looking to capitalize in an insanely lucrative
          industry. For the first time ever, you can monetize your bizopp
          traffic with a cost-effective offer that pays out a whopping 30%
          one-time commisision on each sale you refer.
        </p>

        <p class="texts__paragraphs mb-1">
          That's almost $300 a sale. Signup today.
        </p>
      </div>

      <div class="col-12 col-md-5">
        <div class="text-right">
          <h2 class="texts__giant">
            Earn 30%
          </h2>
          <h3 class="texts__giant-two">
            commission on <br class="d-md-none" />
            each sale
          </h3>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section --bg-affiliate-one">
  <div class="container px-xl">
    <div class="row">
      <div class="col-12">
        <h2 class="texts__title mb-2">Why Promote Us?</h2>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
        <img src="assets/images/icon-money.png " alt=" " />
        <h3>
          Lightning-Fast Monthly Payments
        </h3>
        <p>
          You get paid your affiliate payments on-time, every time, every month,
          straight to your Bitcoin wallet.
        </p>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
        <img src="assets/images/icon-diamond.png " alt=" " />
        <h3>
          Promote an Exclusive Money-Making Offer
        </h3>
        <p>
          Your existing traffic can make you more money with this fresh
          opportunity in the lucrative bizopp niche.
        </p>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
        <img src="assets/images/icon-lapto.png " alt=" " />
        <h3 class="mt-2">
          Real-time Stats <br />
          & Reporting
        </h3>
        <p>
          You're never in the dark with 24/7 instant updates, using integrated
          3rd party affiliate tracking software.
        </p>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
        <img src="assets/images/icon-headphone.png " alt=" " />
        <h3>
          Dedicated Account <br />
          Manager
        </h3>
        <p>
          You'll be assigned an account manager to help you every step of the
          way, whatever you may need.
        </p>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
        <img src="assets/images/icon-translate.png " alt=" " />
        <h3>
          Promote in Multiple Languages
        </h3>
        <p>
          We offer professionally-translated landing pages in both English and
          Spanish (just ask if you need more).
        </p>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-lg-0">
        <img src="assets/images/icon-globe.png " alt=" " />
        <h3>
          Traffic Accepted From All Markets
        </h3>
        <p>
          It doesn't matter where your traffic is from; we cater to all markets
          globally & welcome your visitors.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section --bg-affiliate-two">
  <div class="container">
    <div class="row mb-0">
      <div class="col-12">
        <h2 class="texts__title mb-2">Ready to Get Started?</h2>
        <a (click)="scrollTop()" class="btn mt-4 mb-md-0" style="display: inline-block;"><img class="btn__icon"
            src="assets/images/icon-crown.png " />start
          earning now</a>
      </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container my-5 pb-4">
    <div class="row mb-4">
      <div class="col-12">
        <h2 class="texts__title text-center">Affiliate Program FAQs</h2>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="faqs">
          <h3 class="mb-3">How does it work?</h3>
          <p>
            It's really simple! All you have to do is place one four banners on
            your website, email, or blog. When your visitors click on a banner
            and purchase their own online casino, you'll get paid on those sales
          </p>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="faqs">
          <h3 class="mb-3">How do I get paid?</h3>
          <p>
            You will get paid every month by Bitcoin straight to your Wallet
          </p>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="faqs">
          <h3 class="mb-3">When do I get paid?</h3>
          <p>
            On the 15th of every month, you will recive yout commission payout
            for all sales from the previous month
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<section class="section__contact ">
  <div class="container ">
    <div class="row align-items-center ">

      <div class="col-12 col-md-6 section__contact__img ">
        <img src="assets/images/laptop-cryptobet.png " alt=" ">
      </div>

      <div class="col-12 col-md-6 ">
        <div class="col-12 ">
          <h3 class="section__contact__title mb-3 ">Fill out the <b>Form Below</b> <br> <b>and We'll Contact</b> You ASAP</h3>
        </div>
        <form class="section__contact__form " action=" ">

          <div class="row justify-content-center ">
            <div class="col-12 ">
              <div class="Input mb-3 ">
                <input type="text " id="input " class="Input-text " placeholder="Full Name ">
              </div>
            </div>
          </div>

          <div class="row justify-content-center mb-3 ">

            <div class="col-12 col-md-6 ">
              <div class="Input mb-3 ">
                <input type="email " id="input " class="Input-text " placeholder="Email ">
              </div>
            </div>

            <div class="col-12 col-md-6 ">
              <div class="Input ">
                <input type="text " id="input " class="Input-text " placeholder="Phone Number ">
              </div>
            </div>

            <div class="col-12 ">
              <div class="Input ">
                <textarea id="input " class="Input-text " rows="5 " placeholder="Message "></textarea>
              </div>
            </div>
            <div class="col-12 text-center mt-3 ">
              <button type="submit "><img src="assets/images/btn-send-message.png " alt=" "></button>
            </div>
          </div>
        </form>

      </div>

    </div>
  </div>

</section>-->
