<section class="section-pages">
  <div class="container">
    <div class="row mb-md-4 align-items-center">
      <div class="col-12 col-md-6 col-lg-5 center">
        <img class="px-5" src="assets/images/buycasinos-book.png" alt="" style="max-height: 410px !important;"/>
      </div>

      <div class="col-12 col-md-6 col-lg-7 pl-md-4">
        <div class="mb-3 pages-logo">
          <img
            style="max-width: 250px"
            src="assets/images/buyscasinos-logo.png"
            alt=""
          />
        </div>

        <p class="mb-0 mb-md-4">{{ "buyCasinos.title" | translate }}</p>

        <p class="mb-0 mb-md-4">
          {{ "buyCasinos.text" | translate }}
        </p>
        <div class="btn-container">
          <a
            class="btn mb-0 mb-md-5"
            href="https://www.buycasinos.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ "buyCasinos.btn" | translate }}
            <span> {{ "buyCasinos.btnTwo" | translate }} </span></a
          >
        </div>
      </div>
    </div>

    <div class="row pb-5">
      <div class="col-12">
        <p class="pb-4">
          {{ "buyCasinos.textTwo" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
