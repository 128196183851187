<h2 class="texts__title --manual tab-title mb-3">Create Players</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  To create a new Player go to Dashboard > Create Users > Players. You have to be logged from your Cashier Account.
</p>

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  Fill the form and Click on “Create User” button.
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/create-users/players.png" alt=" subir logo" />
