<h2 class="texts__title --manual tab-title mb-3">Payment System</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  To set Payment System go to Dashboard > Skin > Select Plugins
</p>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/payment-system/payment-one.png" alt=" subir logo" />


<hr class="line-manual mb-4" />

<h2 class="texts__title --manual tab-title mb-3">Payment System</h2>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/payment-system/payment-two.png" alt="" />

<p class="texts__paragraphs --manual tab-p mb-3 contain-pdf">
  Get more details of these instructions on this <a (click)="openDialogCoinpaymentPDF()" style="color: #489CFF;">link</a>
</p>

<hr class="line-manual mb-4" />

<h2 class="texts__title --manual tab-title mb-3">Deposits & Withdrawals</h2>

<p class="texts__paragraphs --manual tab-p mb-3">
  Activates or deactivates by pressing the toggle button.
</p>

<p class="texts__paragraphs --manual tab-p mb-3">
  To edit properties, click on pencil icon
</p>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/payment-system/payment-three.png" alt="" />

<p class="texts__paragraphs --manual tab-p mb-3">
  You can set the maximum and minimum amount allowed.
</p>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/payment-system/payment-four.png" alt="" />

<p class="texts__paragraphs --manual tab-p mb-3">
  To confirm it, Click on Accept button. Don’t forget update changes
</p>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/payment-system/payment-five.png" alt="" />

<hr class="line-manual mb-4" />

<h2 class="texts__title --manual tab-title mb-3">KYC & Bonus</h2>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/payment-system/payment-six.png" alt="" />

<p class="texts__paragraphs --manual tab-p mb-3">
  Activates or deactivates by pressing the toggle button.
</p>
