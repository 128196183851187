import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tpay",
  templateUrl: "./tpay.component.html",
  styleUrls: ["./tpay.component.scss"],
})
export class TpayComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    document.getElementById("defaultOpen").click();
  }

  openTab(e, tab) {
    let i, tabcontent, tablinks;

    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tab).style.display = "block";
    e.currentTarget.className += " active";
  }
}
