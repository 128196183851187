<header id="thank-you" class="thank-you">
  <div class="container">
    <a routerLink="/" class="logo">
      <img src="assets/images/payment/logo.png" width="285" alt="RGT" />
    </a>
    <div class="row">
      <div class="col-12">
        <h3>THANK YOU!</h3>

        <p>
          <b>We appreciate your business. Check your email in a few minutes for
            your receipt and a welcome message.</b>
        </p>

        <p>
          If you paid via crypto, you'll need to wait until your transaction has 2 confirmations before you'll receive your receipt and welcome email. If you need to check the status of your payment, check your inbox for an email from CoinPayments.net with "New
          Payment Submitted" in the subject line. You'll find a link to view the status of your payment there.
        </p>

        <p>
          An Account Manager will reach out to you ASAP to start setting up your online casino. Our business hours are Monday - Friday, 10 am to 7 pm ET. If you ordered outside of those hours, please wait for the next business day for us to contact you. While you’re
          waiting for your Account Manager to contact you, we’ve got a few things for you to do to get you started. Check your email in a few minutes to get started.
        </p>

        <p>
          In case you need to reach out to your Account Manager for anything, here’s how:
        </p>

        <p>
          Name: Julie Massaguer <br />
          <a href="mailto:julie@rgt.com" style="color: #fff !important; font-weight: normal !important; cursor: pointer !important;">Email: julie@rgt.com</a>
        </p>
      </div>
    </div>
  </div>
</header>