<section class="headings --internal-pages --bg-two --efin">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-11 col-md-6 col-lg-5">


        <div class="wistia_responsive_padding mb-3 img-efin mb-4 mb-md-0" style="position: relative">
          <div class="wistia_responsive_wrapper">
            <div class="wistia_embed wistia_async_fizbwqx7ll videoFoam=true" style="height: 100%; position: relative; width: 100%">
              <div class="wistia_swatch" style="
                  height: 100%;
                  left: 0;
                  opacity: 0;
                  overflow: hidden;
                  position: absolute;
                  top: 0;
                  transition: opa city 200ms;
                  width: 100%;
                ">
                <img src="https://fast.wistia.com/embed/medias/fizbwqx7ll/swatch" style="
                    filter: blur(5px);
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                  " alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 text-center-md letf-d pl-lg-5">
        <img class="mb-3 logo-efin" src="assets/images/efin-logo.png" alt="" />
        <p class="left px-4 px-md-0 mb-4">
          {{ "efin.textHeader" | translate }}
        </p>
        <a class="btn" href="https://efin.com/" target="_blank" rel="noopener noreferrer">
          {{ "efin.btnHeader" | translate }} <b>EFIN</b></a
        >
      </div>
    </div>
  </div>
</section>

<section class="section-efin-one">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 col-lg-5 px-5 px-md-4 order-md-1">
        <img
          class="img-api-one mb-4 mb-md-0"
          src="assets/images/tpay-img.png"
          alt=""
        />
      </div>

      <div class="col-12 col-md-6 col-lg-7 order-md-0">
        <h2 class="texts__title mb-4 mb-md-2">
          {{ "efin.multiCurrencuTitle" | translate }}
        </h2>
        <p class="texts__paragraphs">
          {{ "efin.multiCurrencuText" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-efin-two">
  <div class="container">
    <div class="row align-items-center mb-2">
      <div class="col-12">
        <div class="bg">
          <h3 style="text-align: left" class="texts__title px-lg-5">
            {{ "efin.futureVersion" | translate }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-efin-three mt-lg-5">
  <div class="container">
    <div class="row align-items-center mb-2 pt-lg-5">
      <div class="col-12 col-md-6 col-lg-5 order-md-1">
        <img
          class="img-api-one mb-4"
          src="assets/images/tpay-img-two.png"
          alt=""
        />
      </div>
      <div class="col-12 col-md-6 col-lg-7 order-md-0">
        <h2 class="texts__title mb-4 mb-md-2">
          {{ "efin.lpsTitle" | translate }}
          <br class="d-md-none" />
          {{ "efin.lpsTitleTwo" | translate }}
        </h2>
        <div class="d-lg-none">
          <p class="texts__paragraphs mb-3">
            {{ "efin.lpsMobilText" | translate }}
          </p>
          <p class="texts__paragraphs">
            {{ "efin.lpsMobilTextTwo" | translate }}
          </p>
        </div>
        <div class="d-none d-lg-block">
          <p class="texts__paragraphs mb-3">
            {{ "efin.lpsDesktopText" | translate }}
          </p>
          <p class="texts__paragraphs mb-3">
            {{ "efin.lpsDesktopTextTwo" | translate }}
          </p>
          <p class="texts__paragraphs mb-3">
            {{ "efin.lpsDesktopTextThree" | translate }}
          </p>
          <p class="texts__paragraphs mb-3">
            {{ "efin.lpsDesktopTextFour" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-efin-four">
  <div class="container">
    <div class="row align-items-center mb-2">
      <div class="col-12 col-md-6 col-lg-5">
        <img class="mb-4" src="assets/images/efin-img.png" alt="" />
      </div>
      <div class="col-12 col-md-6 col-lg-7">
        <h2 class="texts__title mb-2 mb-md-2">
          {{ "efin.dexTilte" | translate }}
        </h2>
        <p class="texts__paragraphs mb-3">
          {{ "efin.dexText" | translate }}
        </p>
        <p class="texts__paragraphs mb-3">
          {{ "efin.dexTextTwo" | translate }}
        </p>
        <p class="texts__paragraphs mb-3">
          {{ "efin.dexTextThree" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-efin-five">
  <div class="container">
    <div class="row mb-2">
      <div class="col-12 col-md-6 col-lg-5 order-md-2">
        <img
          class="px-5 px-md-0 mb-4"
          src="assets/images/efin-img-two.png"
          alt=""
        />
      </div>
      <div class="col-12 col-md-6 col-lg-7 order-md-1">
        <h2 class="texts__title mb-3 mb-md-2">
          {{ "efin.verifyTitle" | translate }}
          <br class="d-md-none" />
          {{ "efin.verifyTitleTwo" | translate }}
        </h2>
        <p class="texts__paragraphs mb-3">
          {{ "efin.verifyText" | translate }}
        </p>
        <p class="texts__paragraphs mb-4">
          {{ "efin.verifyTextTwo" | translate }}
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 order-md-3">
        <div class="flex mt-3 mt-lg-5">
          <img src="assets/images/double-arrow.svg" alt="" />
          <h3 class="texts__title --two px-lg-3">
            {{ "efin.verifyBlock" | translate }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-efin-six">
  <div class="container">
    <div class="row mb-2">
      <div class="col-12 text-center">
        <h2 class="texts__title px-4 mt-lg-4">
          {{ "efin.centralizedExchangesTitle" | translate }}
          <br class="d-none d-md-block" />
          {{ "efin.centralizedExchangesTitleTwo" | translate }}
        </h2>
      </div>
      <div class="col-12 col-md-6 mp px-lg-5">
        <div class="d-md-none">
          <p class="texts__paragraphs mb-3">
            {{ "efin.centralizedExchangesTitleMobile" | translate }}
          </p>
        </div>
        <div class="d-none d-md-block">
          <p class="texts__paragraphs mb-3">
            {{ "efin.centralizedExchangesText" | translate }}
          </p>
          <p class="texts__paragraphs mb-3">
            {{ "efin.centralizedExchangesTextTwo" | translate }}
          </p>
        </div>
      </div>
      <div class="row"></div>
    </div>
  </div>
</section>

<section class="section-efin-seven">
  <div class="container">
    <div class="row justify-content-lg-center mb-2">
      <div class="col-12 col-md-5">
        <img src="assets/images/efin-computer.png" alt="" />
      </div>
      <div class="col-12 col-md-7">
        <h3 class="texts__title --two mb-4">
          {{ "efin.decentralizedExchangesTitle" | translate }}
        </h3>
        <p class="texts__paragraphs mb-3">
          {{ "efin.decentralizedExchangesText" | translate }}
        </p>
        <p class="texts__paragraphs mb-3">
          {{ "efin.decentralizedExchangesTextTwo" | translate }}
        </p>
        <p class="texts__paragraphs mb-3">
          {{ "efin.decentralizedExchangesTextThree" | translate }}
        </p>
        <p class="texts__paragraphs mb-3">
          {{ "efin.decentralizedExchangesTextFour" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-efin-eight">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-lg-10">
        <h2 class="texts__title --two">
          {{ "efin.crossChainText" | translate }}
        </h2>
      </div>
      <div class="col-12">
        <div class="flex">
          <a
            class="btn mt-3 mt-l-0"
            href="https://efin.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ "efin.btnV" | translate }} EFIN</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
