//IMPORT MODULES
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AngularMaterialModule } from "./angular-material.module";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';


//IMPORT TRANSLATE

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


//IMPORT COMPONENTS
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/shared/footer/footer.component";
import { HeaderComponent } from "./components/shared/header/header.component";
import { HomeComponent } from "./components/pages/home/home.component";
import { ApiDirectComponent } from "./components/pages/api-direct/api-direct.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { AffiliatesComponent } from "./components/pages/affiliates/affiliates.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { ThankYouPageComponent } from "./components/pages/thank-you-page/thank-you-page.component";
import { RefundPolicyComponent } from "./components/pages/refund-policy/refund-policy.component";
import { PaymentComponent } from "./components/pages/payment/payment.component";
import { PaymentsService } from "./services/payments.service";

import { OurCustomersComponent } from "./components/pages/our-customers/our-customers.component";

import { TermsAndconditionsComponent } from "./components/modal/terms-andconditions/terms-andconditions.component";
import { PrivacyPolicyModalComponent } from "./components/modal/privacy-policy-modal/privacy-policy-modal.component";
import { FrequentQuestionsComponent } from "./components/modal/frequent-questions/frequent-questions.component";
import { LeviathanComponent } from "./components/pages/leviathan/leviathan.component";
import { GamecloudPageComponent } from "./components/pages/gamecloud-page/gamecloud-page.component";
import { CryptobetComponent } from "./components/pages/cryptobet/cryptobet.component";
import { CasinoHostingComponent } from "./components/pages/casino-hosting/casino-hosting.component";
import { CasinoLicenseComponent } from "./components/pages/casino-license/casino-license.component";
import { CointellerComponent } from "./components/pages/cointeller/cointeller.component";
import { InfinitesimalComponent } from "./components/pages/infinitesimal/infinitesimal.component";
import { BlastComponent } from "./components/pages/blast/blast.component";
import { EfinComponent } from "./components/pages/efin/efin.component";
import { TpayComponent } from "./components/pages/tpay/tpay.component";
import { BlocksizeComponent } from "./components/pages/blocksize/blocksize.component";
import { BuycasinosComponent } from "./components/pages/buycasinos/buycasinos.component";
import { CasinoSetupComponent } from "./components/pages/casino-setup/casino-setup.component";
import { HandbookComponent } from "./components/pages/handbook/handbook.component";
import { FaviconComponent } from './components/pages/handbook/skin/settings/favicon/favicon.component';
import { NameComponent } from './components/pages/handbook/skin/settings/name/name.component';
import { LogoComponent } from './components/pages/handbook/skin/settings/logo/logo.component';
import { DesktopComponent } from './components/pages/handbook/skin/banners/desktop/desktop.component';
import { MobileComponent } from './components/pages/handbook/skin/banners/mobile/mobile.component';
import { LayoutComponent } from './components/pages/handbook/skin/select-plugins/layout/layout.component';
import { ExternalPlatformsComponent } from './components/pages/handbook/external-platforms/external-platforms.component';
import { CasinoSupportComponent } from './components/modal/handbook/casino-support/casino-support.component';
import { CoinpaymentPdfComponent } from './components/modal/handbook/coinpayment-pdf/coinpayment-pdf.component';
import { PaymentSystemComponent } from './components/pages/handbook/skin/select-plugins/payment-system/payment-system.component';
import { PlayersComponent } from './components/pages/handbook/create-users/players/players.component';
import { CashierComponent } from './components/pages/handbook/create-users/cashier/cashier.component';
import { AgentComponent } from './components/pages/handbook/create-users/agent/agent.component';
import { BackgroundImageLoadedDirective } from "./directives/appBackgroundImageLoaded";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    ApiDirectComponent,
    AboutComponent,
    AffiliatesComponent,
    ContactComponent,
    NotFoundComponent,
    ThankYouPageComponent,
    RefundPolicyComponent,
    PaymentComponent,
    OurCustomersComponent,
    TermsAndconditionsComponent,
    PrivacyPolicyModalComponent,
    FrequentQuestionsComponent,
    LeviathanComponent,
    GamecloudPageComponent,
    CryptobetComponent,
    CasinoHostingComponent,
    CasinoLicenseComponent,
    CointellerComponent,
    InfinitesimalComponent,
    BlastComponent,
    EfinComponent,
    TpayComponent,
    BlocksizeComponent,
    BuycasinosComponent,
    CasinoSetupComponent,
    HandbookComponent,
    LogoComponent,
    FaviconComponent,
    NameComponent,
    DesktopComponent,
    MobileComponent,
    LayoutComponent,
    ExternalPlatformsComponent,
    CasinoSupportComponent,
    CoinpaymentPdfComponent,
    PaymentSystemComponent,
    PlayersComponent,
    CashierComponent,
    AgentComponent,
    BackgroundImageLoadedDirective
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    FlexLayoutModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [PaymentsService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    TermsAndconditionsComponent,
    PrivacyPolicyModalComponent,
    FrequentQuestionsComponent,
  ],
})
export class AppModule { }
