import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Payments } from '../interfaces/payments';
import { Observable } from 'rxjs';
import { DataRowOutlet } from '@angular/cdk/table';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AffiliatesService {

  public api = 'https://api.rgt.com';
  // public api = 'http://127.0.0.1:8005';


  constructor(private http: HttpClient) { }


  registerAffiliates(data) {

    return this.http.post(this.api + '/affiliates_register', data, httpOptions).toPromise();
  }

  loginAffiliates(data) {

    return this.http.post(this.api + '/affiliates_auth', data, httpOptions).toPromise();
  }

}
