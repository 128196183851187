import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-favicon',
  templateUrl: './favicon.component.html',
  styleUrls: ['./favicon.component.scss']
})
export class FaviconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
