import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blocksize',
  templateUrl: './blocksize.component.html',
  styleUrls: ['./blocksize.component.scss']
})
export class BlocksizeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
