<section class="headings --internal-pages --bg-two --efin">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5">
        <img class="mb-3 img-efin mb-4 mb-md-0 px-4" src="assets/images/tpay-header-img.png" alt="" />
      </div>

      <div class="col-12 col-md-7 center-m pl-lg-5">
        <img class="mb-3 logo-efin" src="assets/images/tpay-logo.png" alt="" />
        <p class="tpay-p mb-3">
          {{ "tokenPay.textHeader" | translate }}
        </p>
        <a href="https://github.com/tpaysecure" target="_blank" rel="noopener noreferrer" class="btn">TPAY GITHUB</a
        >
      </div>
    </div>
  </div>
</section>

<section class="section-tpay-one py-5">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-lg-10">
        <p class="pb-4">
          {{ "tokenPay.text" | translate }}
        </p>

        <p>
          {{ "tokenPay.textTwoOne" | translate }}
          <a
            href="https://explorer.tpay.ai/"
            target="_blank"
            rel="noopener noreferrer"
            style="color: rgba(150, 53, 158);"
          >
            {{ "tokenPay.textTwoLink" | translate }}
          </a> {{ "tokenPay.textTwoTwo" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-tpay-two">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="tab">
          <button class="tablinks" (click)="openTab($event, 'one')" id="defaultOpen">
            {{ "tokenPay.blockchainTitle" | translate }}
          </button>
          <button class="tablinks px-p" (click)="openTab($event, 'two')">
            {{ "tokenPay.walletTitle" | translate }}
          </button>
        </div>

        <!-- Tab content -->
        <div id="one" class="tabcontent">
          <div class="row">
            <div class="col-12">
              <p class="texts__paragraphs pt-3 tab-p mb-5 pb-lg-5 px-lg-5">
                {{ "tokenPay.blockchainText" | translate }}
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-4 center-m">
              <img src="assets/images/lock-icon.svg" alt="" />
              <h2 class="texts__title tab-title mb-3 mt-2">
                {{ "tokenPay.blockchainItemTitle" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-5 pr-lg-4">
                {{ "tokenPay.blockchainItemText" | translate }}
              </p>
            </div>

            <div class="col-12 col-md-6 col-lg-4 center-m">
              <img src="assets/images/lock-two-icon.svg" alt="" />
              <h2 class="texts__title tab-title mb-3 mt-2">
                {{ "tokenPay.blockchainItemTitleTwo" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-5 pr-lg-4">
                {{ "tokenPay.blockchainItemTextTwo" | translate }}
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-4 center-m">
              <img src="assets/images/idea-icon.svg" alt="" />
              <h2 class="texts__title tab-title mb-3 mt-2">
                {{ "tokenPay.blockchainItemTitleThree" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-5 pr-lg-4">
                {{ "tokenPay.blockchainItemTextThree" | translate }}
              </p>
            </div>
          </div>
        </div>

        <div id="two" class="tabcontent">
          <div class="row">
            <div class="col-12">
              <p class="texts__paragraphs tab-p mb-5 px-lg-5">
                {{ "tokenPay.walletText" | translate }}
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-4 center-m">
              <img src="assets/images/lock-two-icon.svg" alt="" />
              <h2 class="texts__title tab-title mb-3 mt-2">
                {{ "tokenPay.walletItemTitle" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-5 pr-lg-4">
                {{ "tokenPay.walletItemText" | translate }}
              </p>
            </div>

            <div class="col-12 col-md-6 col-lg-4 center-m">
              <img src="assets/images/idea-icon.svg" alt="" />
              <h2 class="texts__title tab-title mb-3 mt-2">
                {{ "tokenPay.walletItemTitleTwo" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-5 pr-lg-4">
                {{ "tokenPay.walletItemTextTwo" | translate }}
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-4 center-m">
              <img src="assets/images/lock-three-icon.svg" alt="" />
              <h2 class="texts__title tab-title mb-3 mt-2">
                {{ "tokenPay.walletItemTitleThree" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-5 pr-lg-4">
                {{ "tokenPay.walletItemTextThree" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-tpay-three">
  <div class="container">
    <div class="row align-items-center justify-content-center mb-2 pt-lg-1">
      <div class="col-12 col-md-6 col-lg-5">
        <img class="img-api-one mb-4 pt-4 px-4 px-lg-0" src="assets/images/tpay-image.png" alt="" />
      </div>
      <div class="col-12 col-md-6 col-lg-5">
        <h2 class="texts__title mb-4 mb-md-2 pt-lg-5">
          {{ "tokenPay.desktopTitle" | translate }}
        </h2>
        <p class="texts__paragraphs mb-3 pl-p-p">
          {{ "tokenPay.desktopText" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-tpay-four">
  <div class="container">
    <div class="row align-items-center mb-2 no-gutters justify-content-center">
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 center order-md-1">
        <img class="mb-4 img-p mb-lg-0 img-p-o" src="assets/images/tpay-image-two.png" alt="" />
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-6 order-md-0 pl-lg-5">
        <h2 class="texts__title mb-2 mb-md-3">
          {{ "tokenPay.mobileTitle" | translate }}
        </h2>
        <p class="texts__paragraphs mb-4 pr-p-p-t">
          {{ "tokenPay.mobileText" | translate }}
        </p>
        <p class="texts__paragraphs mb-3 mb-lg-0">
          {{ "tokenPay.mobileTextTwo" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-tpay-five">
  <div class="container">
    <div class="row mb-2 mb-md-0 align-items-center justify-content-center">
      <div class="col-12 col-md-5 col-lg-6">
        <img class="px-5 px-md-0 mb-4 pt-4 mb-md-0" src="assets/images/tpay-image-three.png" alt="" />
      </div>
      <div class="col-12 col-md-5 col-lg-5">
        <h2 class="texts__title mb-3 mb-md-2">
          {{ "tokenPay.paperWalletTitle" | translate }}
        </h2>
        <p style="text-align: left" class="texts__paragraphs mb-3">
          {{ "tokenPay.paperWalletText" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-tpay-six">
  <div class="container">
    <div class="row d-md-none">
      <div class="col-12">
        <div class="center">
          <img style="max-width: 150px; text-align: center" class="mb-4 img-p mt-3 mb-lg-0" src="assets/images/tpay-image-four.png" alt="" />
        </div>

        <!-- <h2 class="texts__title mt-lg-4 mb-5">Payment Platform</h2>

        <h3 class="pr-3 mb-4">
          TPAY is a secure online payments aggregator specifically designed for
          the gaming industry.
        </h3> -->
        <h3 class="pr-4 mb-4">
          {{ "tokenPay.paymentPlatformTitle" | translate }}
        </h3>
        <p class="texts__paragraphs mb-3">
          {{ "tokenPay.paymentPlatformText" | translate }}
        </p>
        <p class="texts__paragraphs mb-3">
          {{ "tokenPay.paymentPlatformTextTwo" | translate }}
        </p>
      </div>
    </div>
    <div class="d-none d-md-flex row align-items-center justify-content-center mb-2 d-none">
      <div class="col-12 col-md-6 col-lg-4">
        <img class="mb-4 img-p mt-5 mb-lg-0 img-p-o" src="assets/images/tpay-image-four.png" alt="" />
      </div>

      <div class="col-12 col-md-6 col-lg-8">
        <h2 class="texts__title">
          {{ "tokenPay.paymentPlatformTitle" | translate }}
        </h2>
        <p class="texts__paragraphs mb-3">
          {{ "tokenPay.paymentPlatformText" | translate }}
        </p>
        <p class="texts__paragraphs mb-3">
          {{ "tokenPay.paymentPlatformTextTwo" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-tpay-seven">
  <div class="container">
    <div class="row"></div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 col-lg-6 position-relative">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="assets/images/coinpaymet-logo.svg" alt="Avatar" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.lowFee5" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.drbc" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div class="px-lg-5">
                <img src="assets/images/logos-coin/bitcoin.svg" alt="" />
                <img style="height: 45px" src="assets/images/logos-coin/ethereum.svg" alt="" />
                <img src="assets/images/logos-coin/litecoin.svg" alt="" />
                <img src="assets/images/logos-coin/binance.svg" alt="" />
                <img src="assets/images/logos-coin/tron.svg" alt="" />
                <img src="assets/images/logos-coin/monero.svg" alt="" />
                <img src="assets/images/logos-coin/bitcoin-cash.svg" alt="" />
                <img src="assets/images/logos-coin/verge.svg" alt="" />
                <img src="assets/images/logos-coin/tpay.png" alt="" />
                <img src="assets/images/logos-coin/eos.svg" alt="" />
                <img src="assets/images/logos-coin/lbtc.png" alt="" />
                <img src="assets/images/logos-coin/dogecoin.svg" alt="" />
                <img src="assets/images/logos-coin/tether.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 position-relative-d">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="assets/images/coinspaid.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.lowFee8" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div class="px-lg-5">
                <img src="assets/images/logos-coin/bitcoin.svg" alt="" />
                <img style="height: 45px" src="assets/images/logos-coin/ethereum.svg" alt="" />
                <img src="assets/images/logos-coin/litecoin.svg" alt="" />
                <img src="assets/images/logos-coin/binance.svg" alt="" />
                <img src="assets/images/logos-coin/cardano.svg" alt="" />
                <img src="assets/images/logos-coin/bitcoin-cash.png" alt="" />
                <img style="height: 45px" src="assets/images/logos-coin/ethereum-classic.svg" alt="" />
                <img src="assets/images/logos-coin/neo.svg" alt="" />
                <img src="assets/images/logos-coin/augur.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/wlandPay.svg" alt="Icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.hccpf7" | translate }}</li>
                <li>{{ "tokenPay.pcbp" | translate }}</li>
                <li>{{ "tokenPay.dacc" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />
                <img src="assets/images/logos-coin/jcb.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/Payeer.svg" alt="Icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/cash.svg" alt="" />
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />
                <img src="assets/images/logos-coin/maestro.svg" alt="" />
                <img src="assets/images/logos-coin/advcash.svg" alt="" />
                <img src="assets/images/logos-coin/qiwi.svg" alt="" />
                <img src="assets/images/logos-coin/yandex.svg" alt="" />

                <img src="assets/images/logos-coin/megafon.svg" alt="" />
                <img src="assets/images/logos-coin/beeline.svg" alt="" />
                <img src="assets/images/logos-coin/litecoin.svg" alt="" />
                <img style="height: 45px" src="assets/images/logos-coin/ethereum.svg" alt="" />
                <img src="assets/images/logos-coin/bitcoin-cash.svg" alt="" />
                <img src="assets/images/logos-coin/dash.svg" alt="" />
                <img src="assets/images/logos-coin/ripple.svg" alt="" />
                <img src="assets/images/logos-coin/tether.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/advcash.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />
                <img src="assets/images/logos-coin/mir.svg" alt="" />
                <img src="assets/images/logos-coin/SepaLogoEN.jpg" alt="" />
                <img src="assets/images/logos-coin/qiwi.svg" alt="" />
                <img src="assets/images/logos-coin/yandex.svg" alt="" />
                <img src="assets/images/logos-coin/megafon.svg" alt="" />

                <img src="assets/images/logos-coin/zcash.svg" alt="" />
                <img style="height: 45px" src="assets/images/logos-coin/ethereum.svg" alt="" />
                <img src="assets/images/logos-coin/bitcoin-cash.svg" alt="" />
                <img src="assets/images/logos-coin/ripple.svg" alt="" />
                <img src="assets/images/logos-coin/tether.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/MoonPay.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.ccpfpd" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />
                <img src="assets/images/logos-coin/maestro.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/Cash.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.nfpct" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/p2p.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/Skrill.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupModerate" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />
                <img src="assets/images/logos-coin/jcb.svg" alt="" />
                <img src="assets/images/logos-coin/american-express.svg" alt="" />
                <img src="assets/images/logos-coin/diners-club-international.svg" alt="" />
                <img src="assets/images/logos-coin/bank-transfer.svg" alt="" /><img src="assets/images/logos-coin/paysafe-card.svg" alt="" />

                <img src="assets/images/logos-coin/bitcoin.svg" alt="" />
                <img src="assets/images/logos-coin/trustly-logo.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/pm.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupEasy" | translate }}</li>
                <li>{{ "tokenPay.lf5a" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/bank-transfer.svg" alt="" />

                <img src="assets/images/logos-coin/bitcoin.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/paysafecard.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupModerate" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />

                <img src="assets/images/logos-coin/p2p.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/Qiwi.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupModerate" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.noPlayerCharge" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />

                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/qiwi.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/Neteller.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupDifficult" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.cpwf" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/banco-do-brasil.svg" alt="" />
                <img src="assets/images/logos-coin/Banco_de_Chile_logo.svg" alt="" />
                <img src="assets/images/logos-coin/bitcoin.svg" alt="" />
                <img src="assets/images/logos-coin/przelewy24.svg" alt="" />
                <img src="assets/images/logos-coin/poli.svg" alt="" />
                <img src="assets/images/logos-coin/neos.png" alt="" />
                <img src="assets/images/logos-coin/klarna.svg" alt="" />
                <img src="assets/images/logos-coin/giropay.svg" alt="" />
                <img src="assets/images/logos-coin/paysafe-card.svg" alt="" />
                <img src="assets/images/logos-coin/qiwi.svg" alt="" />
                <img src="assets/images/logos-coin/bancontact-mister-cash.svg" alt="" />
                <img src="assets/images/logos-coin/ideal.svg" alt="" />
                <img src="assets/images/logos-coin/santander.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/ecopaysz.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupModerate" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.cpwf" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/ecopayz.png" alt="" />
                <img src="assets/images/logos-coin/zest.svg" alt="" />
                <img src="assets/images/logos-coin/bank-transfer.svg" alt="" />
                <img src="assets/images/logos-coin/przelewy24.svg" alt="" />
                <img src="assets/images/logos-coin/poli.svg" alt="" />
                <img src="assets/images/logos-coin/neos.png" alt="" />
                <img src="assets/images/logos-coin/klarna.svg" alt="" />
                <img src="assets/images/logos-coin/giropay.svg" alt="" />
                <img src="assets/images/logos-coin/paysafe-card.svg" alt="" />
                <img src="assets/images/logos-coin/qiwi.svg" alt="" />
                <img src="assets/images/logos-coin/bancontact-mister-cash.svg" alt="" />
                <img src="assets/images/logos-coin/ideal.svg" alt="" />
                <img src="assets/images/logos-coin/payu.svg" alt="" />
                <img src="assets/images/logos-coin/santander.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/Instadebit.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupDifficult" | translate }}</li>
                <li>{{ "tokenPay.lfcnb" | translate }}</li>
                <li>{{ "tokenPay.cpwf" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/bank-transfer.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/yandex.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupModerate" | translate }}</li>
                <li>{{ "tokenPay.lmfds" | translate }}</li>
                <li>{{ "tokenPay.cpwf" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />
                <img src="assets/images/logos-coin/maestro.svg" alt="" />
                <img src="assets/images/logos-coin/american-express.svg" alt="" />
                <img src="assets/images/logos-coin/diners-club-international.svg" alt="" />
                <img src="assets/images/logos-coin/apple-pay.svg" alt="" />
                <img src="assets/images/logos-coin/google-pay.svg" alt="" />
                <img src="assets/images/logos-coin/yandex.svg" alt="" />
                <img src="assets/images/logos-coin/google-pay.svg" alt="" />
                <img src="assets/images/logos-coin/webmoney.svg" alt="" />
                <img src="assets/images/logos-coin/qiwi.svg" alt="" />
                <img src="assets/images/logos-coin/WeChat_Pay.png" alt="" />
                <img src="assets/images/logos-coin/sberbank.svg" alt="" />
                <img src="assets/images/logos-coin/tinkoff.png" alt="" />
                <img src="assets/images/logos-coin/beeline.svg" alt="" />
                <img src="assets/images/logos-coin/megafon.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/WebMoney.svg" alt="icon" />
              <p class="d-flex align-items-start"></p>
              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupModerate" | translate }}</li>
                <li>{{ "tokenPay.lfds" | translate }}</li>
                <li>{{ "tokenPay.cpwf" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/visa.svg" alt="" />
                <img src="assets/images/logos-coin/mastercard.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="flip-card flip-card-two">
          <div class="flip-card-inner">
            <div class="flip-card-front --px-p">
              <img src="assets/images/bb.svg" alt="icon" />

              <ul style="list-style: url('assets/images/payment/list_icon.svg')">
                <li>{{ "tokenPay.accountSetupModerate" | translate }}</li>
                <li>{{ "tokenPay.lfbbd" | translate }}</li>
                <li>{{ "tokenPay.cpwf" | translate }}</li>
                <li>{{ "tokenPay.pdci" | translate }}</li>
              </ul>
            </div>
            <div class="flip-card-back">
              <div>
                <img src="assets/images/logos-coin/bank-transfer.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>