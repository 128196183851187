<header id="header" class="header">
  <div class="header__menu-icon" (click)="openNav()" id="landing-setup">
    <img src="assets/images/icon-menu.svg" class="menu-btn" alt="icon menu" />
   <!--  <div class="nav-toggle"><div class="nav-toggle-bar"></div></div> -->
  </div>
  <div class="container">
    <div class="header__container">
      <div id="logoh" class="header__logo">
        <a routerLink="/"><img src="assets/images/logo-rgt.png" alt="" /> </a>
      </div>
      <span class="form-inline">
        <div id="dropdown" class="dropdown d-inline-block">
          <button class="dropbtn">
            <img
              class="img-flat"
              src="assets/images/{{ languageService.selectedLanguage }}.png"
              alt=""
            />
            <span class="d-none d-md-block"> {{ languageService.selectedLanguage }}</span>
      </button>
      <div id="myDropdown" class="dropdown-content">
        <button id="en" (click)="toogleLanguage($event)" *ngIf="languageService.selectedLanguage != 'en'">
              <img src="assets/images/en.png" id="en" alt="" />
              <span id="en" class="d-none d-md-block">en</span>
            </button>

        <button id="es" (click)="toogleLanguage($event)" *ngIf="languageService.selectedLanguage != 'es'">
              <img src="assets/images/es.png" alt="" id="es" />
              <span id="es" class="d-none d-md-block">es</span>
            </button>

        <button id="pt" (click)="toogleLanguage($event)" *ngIf="languageService.selectedLanguage != 'pt'">
              <img src="assets/images/pt.png" alt="" id="pt" />
              <span id="pt" class="d-none d-md-block">pt</span>
            </button>
      </div>
    </div>
    <div class="d-inline-block mx-4" style="position: fixed;">
      <button routerLink="contact" style="background-color: rgba(150, 53, 158, 0.5); color:#fff; cursor:pointer; height: 40px; width: max-content; border-radius: 5px; padding-left: 15px; padding-right: 15px;"> <mat-icon class="icon-text">contact_support</mat-icon> <span class="ml-4">{{ "contact.subtitle" | translate }}</span>  </button>
    </div>

    </span>

  </div>
  </div>

  <div id="mySidenav" class="sidenav">
   <!--  <a href="javascript:void(0)" class="closebtn mb-3" (click)="closeNav()"><img src="assets/images/menu-close.svg" alt="" /></a>
    <span>{{'home.bettingTitle' | translate | titlecase}}</span>
    <a class="mwi" routerLink="gamecloud-page" (click)="closeNav()">
      <img src="assets/images/nube.svg" alt="" /> Gamecloud
    </a>
    <a class="mwi" routerLink="casino-hosting" (click)="closeNav()"><img src="assets/images/as.svg" alt="" /> Casino Hosting</a>
    <a class="mwi" routerLink="cryptobet" (click)="closeNav()"><img src="assets/images/b.svg" alt="" /> CryptoBet | CBET</a>
    <a class="mwi" routerLink="casino-license" (click)="closeNav()"><img src="assets/images/frame.svg" alt="" /> Casino License</a>
    <a class="mwi" routerLink="leviathan" (click)="closeNav()"><img src="assets/images/dragon.svg" alt="" /> Leviathan Games</a>
    <a class="mb-3 mwi" routerLink="buycasinos" (click)="closeNav()"><img src="assets/images/logos/spade.png" alt="" /> BuyCasinos</a>

    <span class="mb-3">Fintech</span>
    <a class="mwi" (click)="closeNav()" routerLink="tpay"><img style="margin-top: -3px" src="assets/images/t.svg" alt="" /> TPAY Checkout
    </a>
    <a class="mwi" (click)="closeNav()" routerLink="efin"><img src="assets/images/f.svg" alt="" /> eFIN Trading</a>
    <a class="mwi" routerLink="cointeller" (click)="closeNav()"><img src="assets/images/g.svg" alt="" /> Cointeller</a>
    <a class="mwi" routerLink="infinitesimal" (click)="closeNav()"><img src="assets/images/infi.svg" alt="" /> Infinitesimal</a>
    <a class="mwi" routerLink="rgt-prime" (click)="closeNav()"><img style="margin-top: -4px" src="assets/images/Crown-rgt-prime.svg" alt="" /> RGT Prime</a>
    <a class="mwi mb-3" routerLink="blocksize" (click)="closeNav()"><img src="assets/images/logos/blocksize.png" alt="" /> Blocksize</a> -->

    <a class="mwi" (click)="closeNav()" routerLink="">{{
      "menu.home" | translate
    }}</a>

<!--     <a class="mwi" (click)="closeNav()" routerLink="about">{{
      "menu.about" | translate
    }}</a> -->

<!--     <a class="mwi" (click)="closeNav()" href="https://affiliates.rgt.com" target="_blank">{{ "menu.affiliates" | translate }}</a
    > -->
    <a class="mwi" (click)="closeNav()" routerLink="gamecloud-api"
      >Gamecloud API</a
    >
    <a class="mwi" (click)="closeNav()" routerLink="our-customers">{{
      "menu.ourCustomers" | translate
    }}</a>
    <a class="mwi" (click)="closeNav()" routerLink="trusted-partners">{{
      "menu.ourPartners" | translate
    }}</a>
    <a class="mwi" routerLink="contact" (click)="closeNav()">{{
      "menu.contact" | translate
    }}</a>
  </div>

</header>
