<section class="section-pages">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5 mb-4 mb-md-5">
        <img
          class="px-5 px-md-0"
          src="assets/images/leviathan-page.png"
          alt=""
        />
      </div>

      <div class="col-12 col-md-6 col-lg-7 pl-md-4 pl-lg-5">
        <div class="mb-3 pt-md-3 pages-logo">
          <img src="assets/images/leviathan-logo.png" alt="" width="348" />
        </div>

        <p class="mb-0 mb-md-4">
          {{ "leviathan.text" | translate }}
        </p>
        <div class="btn-container">
          <a
            class="btn mb-0 mb-md-5"
            href="https://leviathan.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ "leviathan.btn" | translate }}

            <span>
              {{ "leviathan.btnTwo" | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="row pb-5">
      <div class="col-12">
        <p class="pb-4">
          {{ "leviathan.textTwo" | translate }}
        </p>

        <p>
          {{ "leviathan.textThree" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
