<!-- <h2 mat-dialog-title>Terms & Conditions</h2> -->
<button class="close" mat-button mat-dialog-close>X</button>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <div class="row mb-4 mb-lg-5">
      <div class="col-12">
        <h2 class="texts__title --about mb-4 mb-lg-3">
          TERMS AND CONDITIONS
        </h2>

        <p class="texts__paragraphs">
          This Services Agreement (the "Agreement") contains the complete terms
          and conditions which govern your subscription of Website Design,
          Development and other Internet-related services provided by Royal
          Gaming Technology or referred to here as “RGT” (the "Services"). As
          used in this Agreement, "Royal Gaming Technology" means Royal Gaming
          Technology and "Client", "you", or "your" means you. You acknowledge
          that you have read the Agreement, and you agree to its terms and
          conditions and all policies posted on the RGT site. As referred to in
          this Agreement, "Site" refers to a World Wide Web site and "RGT Site"
          refers to the Site located at the URL https://www.rgt.com or any other
          successor Sites owned or maintained by RGT.
        </p>

        <p class="texts__paragraphs">
          The following terms and conditions apply to all website development /
          design services provided by RGT to the Client.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Acceptance
        </h2>

        <p class="texts__paragraphs">
          It is not necessary for any Client to have signed an acceptance of
          these terms and conditions for them to apply. If a Client accepts a
          quote then the Client will be deemed to have satisfied themselves as
          to the terms applying and have accepted these terms and conditions in
          full.
        </p>

        <p class="texts__paragraphs">
          Please read these terms and conditions carefully. Any purchase or use
          of our services implies that you have read and accepted our terms and
          conditions.
        </p>

        <p class="texts__paragraphs">
          Client agrees not to facilitate betting by United States citizens,
          residents, or anybody located in the United States or its territories
          without a gaming license issued by a United States authority.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          What are the fees?
        </h2>

        <p class="texts__paragraphs">
          The base $9995 setup fee includes a white label online casino with
          sportsbook, with cryptocurrency payment processing through
          Coinpayments or CoinsPaid (16 cryptocurrencies included with initial
          setup). Each additional payment method Client wants to enable will
          incur a $250 fee.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Provider Fees
        </h2>

        <p class="texts__paragraphs">
          All games, including Slots, Virtual Casino, Prematch and Live Sports
          Betting, Live Casino, and Virtual Sports are charged either a 15% or
          20% Provider Fee, based on GGR (GGR is defined as the total amount bet
          minus the total amount won). Provider Fee is due and paid weekly every
          Monday. Provider Fees carry a $495 monthly minimum.
        </p>

        <!-- <table style="width: 100%;" class="mb-3">
          <tr>
            <td>$0 - $10,000 GGR</td>
            <td>10.00%</td>
          </tr>
          <tr>
            <td>$10,001 - $25,000 GGR</td>
            <td>7.50%</td>
          </tr>
          <tr>
            <td>$25,001 - $50,000 GGR</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>$50,001+ GGR</td>
            <td>2.50%</td>
          </tr>
        </table>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Processing Fees
        </h2>

        <table style="width: 100%;" class="mb-3">
          <tr>
            <td>$0 - $10,000</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>$10,001 - $25,000</td>
            <td>1.50%</td>
          </tr>
          <tr>
            <td>$25,001 - $50,000</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>$50,001+</td>
            <td>0.50%</td>
          </tr>
        </table> -->

        <!-- <p class="texts__paragraphs">
          Platform and Processing Fees are invoiced monthly along with
          $495/month casino hosting.
        </p> -->

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Turnaround Time and Content Control
        </h2>

        <p class="texts__paragraphs">
          RGT will install and publicly post or supply the Client's website by
          the date specified in the project proposal, or at date agreed with
          Client upon RGT receiving initial payment, unless a delay is
          specifically requested by the Client and agreed by RGT.
        </p>

        <p class="texts__paragraphs">
          In return, the Client agrees to delegate a single individual as a
          primary contact to aid RGT with progressing the commission in a
          satisfactory and expedient manner.
        </p>

        <p class="texts__paragraphs">
          During the project, RGT will require the Client to provide website
          content; text, images, movies and sound files.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Payment
        </h2>

        <p class="texts__paragraphs">
          Invoices will be provided by RGT upon completion but before publishing
          the live website. Invoices are normally sent via email. Invoices are
          due upon receipt.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Additional Expenses
        </h2>

        <p class="texts__paragraphs">
          Client agrees to reimburse RGT for any additional expenses necessary
          for the completion of the work. Examples would be purchase of special
          fonts, stock photography etc.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Web Browsers
        </h2>

        <p class="texts__paragraphs">
          RGT makes every effort to ensure websites are designed to be viewed by
          the majority of visitors. Websites are designed to work with the most
          popular current browsers (e.g. Firefox, Internet Explorer, Google
          Chrome, etc.). Client agrees that RGT cannot guarantee correct
          functionality with all browser software across different operating
          systems.
        </p>

        <p class="texts__paragraphs">
          RGT cannot accept responsibility for web pages which do not display
          acceptably in new versions of browsers released after the website has
          been designed and handed over to the Client. As such, RGT reserves the
          right to quote for any work involved in changing the website design or
          website code for it to work with updated browser software.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Default
        </h2>

        <p class="texts__paragraphs">
          Accounts unpaid thirty (30) days after the date of invoice will be
          considered in default. If the Client in default maintains any
          information or files on RGT's Web space, RGT will, at its discretion,
          remove all such material from its web space. RGT is not responsible
          for any loss of data incurred due to the removal of the service.
          Removal of such material does not relieve the Client of the obligation
          to pay any outstanding charges assessed to the Client's account.
          Clients with accounts in default agree to pay RGT reasonable expenses,
          including legal fees and costs for collection by third-party agencies,
          incurred by RGT in enforcing these Terms and Conditions.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Termination
        </h2>

        <p class="texts__paragraphs">
          Termination of services by the Client must be requested in a written
          notice and will be effective on receipt of such notice. Telephone
          requests for termination of services will not be honoured until and
          unless confirmed in writing. The Client will be invoiced for work
          completed to the date of first notice of cancellation for payment in
          full within thirty (30) days.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Indemnity
        </h2>

        <p class="texts__paragraphs">
          All RGT services may be used for lawful purposes only. You agree to
          indemnify and hold RGT harmless from any claims resulting from your
          use of our service that damages you or any other party.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Copyright
        </h2>

        <p class="texts__paragraphs">
          The Client retains the copyright to data, files and graphic logos
          provided by the Client, and grants RGT the rights to publish and use
          such material. The Client must obtain permission and rights to use any
          information or files that are copyrighted by a third party. The Client
          is further responsible for granting RGT permission and rights for use
          of the same and agrees to indemnify and hold harmless RGT from any and
          all claims resulting from the Client's negligence or inability to
          obtain proper copyright permissions. A contract for website design
          and/or placement shall be regarded as a guarantee by the Client to RGT
          that all such permissions and authorities have been obtained. Evidence
          of permissions and authorities may be requested.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Design Credit
        </h2>

        <p class="texts__paragraphs">
          A link to RGT will appear in either small type or by a small graphic
          at the bottom of the Client's website. If a graphic is used, it will
          be designed to fit in with the overall site design. The Client also
          agrees that the website developed for the Client may be presented in
          RGT's portfolio.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          General
        </h2>

        <p class="texts__paragraphs">
          These Terms and Conditions supersede all previous representations,
          understandings or agreements. The Client's signature below or payment
          of an advance fee constitutes agreement to and acceptance of these
          Terms and Conditions. Payment online is an acceptance of our terms and
          conditions.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Governing Law
        </h2>

        <p class="texts__paragraphs">
          This Agreement shall be governed by Curacao Law.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Liability
        </h2>

        <p class="texts__paragraphs">
          RGT hereby excludes itself, its Employees and or Agents from all and
          any liability from:
        </p>

        <ul>
          <li>Loss or damage caused by any inaccuracy;</li>
          <li>Loss or damage caused by omission;</li>
          <li>
            Loss or damage caused by delay or error, whether the result of
            negligence or other cause in the production of the web site;
          </li>
          <li>
            Loss or damage to clients' artwork/photos, supplied for the site.
            Immaterial whether the loss or damage results from negligence or
            otherwise.
          </li>
        </ul>

        <p class="texts__paragraphs">
          The entire liability of RGT to the Client in respect of any claim
          whatsoever or breach of this Agreement, whether or not arising out of
          negligence, shall be limited to the charges paid for the Services
          under this Agreement in respect of which the breach has arisen.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Severability
        </h2>

        <p class="texts__paragraphs">
          In the event any one or more of the provisions of this Agreement shall
          be held to be invalid, illegal or unenforceable, the remaining
          provisions of this Agreement shall be unimpaired and the Agreement
          shall not be void for this reason alone. Such invalid, illegal or
          unenforceable provision shall be replaced by a mutually acceptable
          valid, legal and enforceable provision, which comes closest to the
          intention of the parties underlying the invalid
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>
