<h2 class="texts__title --manual tab-title mb-3">
  Logo
</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  1. To update your logo go to Dashboard > Skin > Settings
</p>

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  2. Click on "Choose File" button to select your logo file. Remember that the right dimensions must be 231x58px
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/skin/settings/logo/logo-one.png" alt=" subir logo" />

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  3.Press "Update Changes" button in the upper right corner to apply changes and then refresh page to be able to see them.
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/skin/settings/logo/logo-two.png" alt="" />