<h2 class="texts__title --manual tab-title mb-3">Mobile Banner</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  1. To update your Banner go to Dashboard > Skin > Banners
</p>

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  2. Click on “Mobile Center” Banner in Mobile side
</p>

<img
  class="mb-4 mb-lg-5"
  src="assets/images/handbook/skin/banners/desktop/mobile-one.png"
  alt=" subir logo"
/>

<p class="texts__paragraphs --manual tab-p mb-3">
  3. Click on “Choose File” Button to select you banner.
</p>

<img
  class="mb-4 mb-lg-5"
  src="assets/images/handbook/skin/banners/desktop/desktop-two.png"
  alt=""
/>

<p class="texts__paragraphs --manual tab-p mb-3">
  4. Select the page you want your banner to direct your customers.
</p>

<img
  class="mb-4 mb-lg-5"
  src="assets/images/handbook/skin/banners/desktop/desktop-three.png"
  alt=""
/>

<p class="texts__paragraphs --manual tab-p mb-3">
  5. Choose the provider in case of “slots”
</p>

<img
  class="mb-4 mb-lg-5"
  src="assets/images/handbook/skin/banners/desktop/desktop-four.png"
  alt=""
/>

<p class="texts__paragraphs --manual tab-p mb-4">6. Click on “Add” Button</p>

<p class="texts__paragraphs --manual tab-p mb-3">
  Note: If you want to add a different banner or click on “Delete” Button to
  clean it and do it again. You can also change each field by clicking on it.
</p>
