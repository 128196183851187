<section class="headings --internal-pages --bg-two">
  <h1>Manuals</h1>

  <p>
    Manuals to help you raise your knowledge and development on our platforms, <br class="d-none d-md-block" /> dashboard and be able to control all aspects of your casinos efficiently.
  </p>
</section>

<section class="section mt-5">
  <div class="container">
    <div class="row align-items-center mb-2">
      <div class="col-12">
        <div class="tab">
          <button class="tablinks" (click)="openTab($event, 'one')" id="defaultOpen">
            Internal Platforms
          </button>
          <button class="tablinks px-2" (click)="openTab($event, 'two')">
            External Platforms
          </button>
          <button class="tablinks tooltip">
            Videos
            <span class="tooltiptext" style="top: 12px;
            right: 61px;">coming soon</span>
          </button>
        </div>

        <!-- Tab content -->
        <div id="one" class="tabcontent pt-4">
          <div class="row mb-4">
            <div class="col">
              <div class="help">
                <img src="assets/images/help-icon.png" alt="" />
                <p>
                  To be able to make all these changes you must log in from your Cashier's Account.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3 col-lg-3">
              <ul class="menu-dropdown">
                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> User Structure

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 my-lg-3 tooltip">VIP <span class="tooltiptext">coming soon</span></li>
                    <li class="my-2 my-lg-3 tooltip">Warnings <span class="tooltiptext">coming soon</span></li>
                    <li class="my-2 my-lg-3 tooltip">Heirs <span class="tooltiptext">coming soon</span></li>
                  </ul>
                </li>

                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Create Users

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 my-lg-3 link-sub-menu" (click)="
                    secundarySideMenuManual($event, 'players-create-content')
                  ">
                      Players (From Cashier)
                    </li>

                    <li class="my-2 my-lg-3 link-sub-menu" (click)="
                    secundarySideMenuManual($event, 'players-cashier-content')
                  ">
                      Cashier (From Agent)
                    </li>

                    <li class="my-2 my-lg-3 link-sub-menu" (click)="
                    secundarySideMenuManual($event, 'players-agent-content')
                  ">
                      Agent (From Agent)
                    </li>

                  </ul>
                </li>
                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Reports

                  <ul class="menu-dropdown__item__content" (click)="manualSideMenu($event)">
                    <li class="my-2 my-lg-3 tooltip">Transaction history <span class="tooltiptext">coming soon</span></li>
                    <li class="my-2 my-lg-3 tooltip">Bet history <span class="tooltiptext">coming soon</span></li>
                    <li class="my-2 my-lg-3 tooltip">Wallet history <span class="tooltiptext">coming soon</span></li>
                  </ul>
                </li>

                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Admin

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 my-lg-3 tooltip">Pending Withdrawals <span class="tooltiptext">coming soon</span></li>
                    <li class="my-2 my-lg-3 tooltip">Referral program <span class="tooltiptext">coming soon</span></li>
                  </ul>
                </li>
                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Skin

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 mt-lg-4 mb-lg-2 --sub">Settings</li>
                    <li class="my-1 my-lg-2 link-sub-menu" (click)="secundarySideMenuManual($event, 'logo-content')" id="defaultOpenTwo">
                      Logo
                    </li>
                    <li class="my-1 my-lg-2 link-sub-menu" (click)="
                        secundarySideMenuManual($event, 'favicon-content')
                      ">
                      Favicon
                    </li>
                    <li class="my-1 my-lg-2 link-sub-menu" (click)="secundarySideMenuManual($event, 'name-content')">
                      Name
                    </li>
                    <li class="my-2 mt-lg-4 mb-lg-2 --sub">Banners</li>
                    <li class="my-1 my-lg-2 link-sub-menu" (click)="
                        secundarySideMenuManual($event, 'desktop-content')
                      ">
                      Desktop
                    </li>
                    <li class="mt-1 mt-lg-2 link-sub-menu" (click)="
                        secundarySideMenuManual($event, 'mobile-content')
                      ">
                      Mobile
                    </li>
                    <li class="my-2 mt-lg-4 mb-lg-2 --sub">Select plugins</li>
                    <li class="my-1 my-lg-2 link-sub-menu" (click)="
                        secundarySideMenuManual($event, 'layout-content')
                      ">
                      Layout
                    </li>
                    <li class="mt-1 mt-lg-2 link-sub-menu" (click)="
                      secundarySideMenuManual($event, 'payment-system-content')
                    ">Payment System</li>
                    <li class="my-1 my-lg-2 tooltip">Miscelaneous <span class="tooltiptext">coming soon</span></li>
                    <li class="my-1 my-lg-2 tooltip">Support <span class="tooltiptext">coming soon</span></li>
                    <li class="my-1 my-lg-2 tooltip ">Marketing <span class="tooltiptext">coming soon</span></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-9 col-lg-9">

              <app-agent id="players-agent-content" class="content-manual"></app-agent>

              <app-cashier id="players-cashier-content" class="content-manual"></app-cashier>

              <app-players id="players-create-content" class="content-manual"></app-players>

              <app-logo id="logo-content" class="content-manual"></app-logo>

              <app-favicon id="favicon-content" class="content-manual"></app-favicon>

              <app-namehandbook id="name-content" class="content-manual"></app-namehandbook>

              <app-desktop id="desktop-content" class="content-manual"></app-desktop>

              <app-mobile id="mobile-content" class="content-manual"></app-mobile>

              <app-layout id="layout-content" class="content-manual"></app-layout>

              <app-payment-system id="payment-system-content" class="content-manual"></app-payment-system>
            </div>
          </div>
        </div>

        <div id="two" class="tabcontent">
          <app-external-platforms class="row"></app-external-platforms>
        </div>

        <div id="three" class="tabcontent">
          <div class="row">
            <div class="col-12 col-md-3 col-lg-3">
              <ul class="menu-dropdown">
                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> User Structure

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 my-lg-3">Players</li>
                    <li class="my-2 my-lg-3">VIP</li>
                    <li class="my-2 my-lg-3">Warnings</li>
                    <li class="my-2 my-lg-3">Heirs</li>
                  </ul>
                </li>

                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Create Users

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 my-lg-3">Players</li>
                  </ul>
                </li>
                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Reports

                  <ul class="menu-dropdown__item__content" (click)="manualSideMenu($event)">
                    <li class="my-2 my-lg-3">Transaction history</li>
                    <li class="my-2 my-lg-3">Bet history</li>
                    <li class="my-2 my-lg-3">Wallet history</li>
                  </ul>
                </li>

                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Admin

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 my-lg-3">Pending Withdrawals</li>
                    <li class="my-2 my-lg-3">Referral program</li>
                  </ul>
                </li>
                <li class="menu-dropdown__item" (click)="manualSideMenu($event)">
                  <img class="menu-dropdown__item__arrow" src="assets/images/arrow-dropdown.png" alt="" /> Skin

                  <ul class="menu-dropdown__item__content">
                    <li class="my-2 my-lg-3 --active">Settings</li>
                    <li class="my-2 my-lg-3">Banners</li>
                    <li class="my-2 my-lg-3">Select plugins</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-9 col-lg-9">
              <h2 class="texts__title tab-title mb-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </h2>
              <p class="texts__paragraphs tab-p mb-4 mb-lg-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitasse eget praesent augue fames eget lacinia suscipit sollicitudin mauris. Congue ac blandit placerat tortor, in pretium. Quis diam et consequat magna viverra et, amet, erat. Consectetur mus
                senectus cursus in ut sapien urna, sagittis condimentum. Sapien vehicula quam sit mattis tortor.
              </p>

              <img class="mb-4 mb-lg-5" src="assets/images/main-screen-one.png" alt="" />

              <h2 class="texts__title tab-title mb-3">
                Lorem ipsum dolor sit amet.
              </h2>
              <p class="texts__paragraphs tab-p mb-4 mb-lg-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitasse eget praesent augue fames eget lacinia suscipit sollicitudin mauris.
              </p>

              <img class="mb-4 mb-lg-5" src="assets/images/main-screen-two.png" alt="" />

              <h2 class="texts__title tab-title mb-3">
                Lorem ipsum dolor sit amet.
              </h2>
              <p class="texts__paragraphs tab-p mb-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitasse eget praesent augue fames eget lacinia suscipit sollicitudin mauris. Congue ac blandit placerat tortor, in pretium. Quis diam et consequat magna viverra et, amet, erat. Consectetur mus
                senectus cursus in ut sapien urna, sagittis condimentum. Sapien vehicula quam sit mattis tortor.
              </p>
              <p class="texts__paragraphs tab-p mb-4 mb-lg-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitasse eget praesent augue fames eget lacinia suscipit sollicitudin mauris. Congue ac blandit placerat tortor, in pretium. Quis diam et consequat magna viverra et, amet, erat. Consectetur mus
                senectus cursus in ut sapien urna, sagittis condimentum. Sapien vehicula quam sit mattis tortor.
              </p>

              <img class="mb-4 mb-lg-5" src="assets/images/main-screen-three.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
