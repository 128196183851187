import { Component, HostListener, OnInit } from "@angular/core";
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: "app-handbook",
  templateUrl: "./handbook.component.html",
  styleUrls: ["./handbook.component.scss"],
})
export class HandbookComponent implements OnInit {
  collapseContent = document.getElementsByClassName(
    "menu-dropdown__item__content"
  );
  arrowCollapse: any;
  toggler: any;
  constructor() { }

  ngOnInit(): void {
    document.getElementById("defaultOpen").click();
    document.getElementById("defaultOpenTwo").click();

    let menu = document.getElementById("dropdown");

    menu.setAttribute("style", "display:none");

  }

  openTab(e, tab) {
    let tabcontent, tablinks;

    tabcontent = document.getElementsByClassName("tabcontent");

    for (const content of tabcontent) {
      content.style.display = 'none';
    }

    tablinks = document.getElementsByClassName("tablinks");

    for (const link of tablinks) {
      link.className = link.className.replace(" active", "");
    }

    document.getElementById(tab).style.display = "block";
    e.currentTarget.className += " active";
  }

  manualSideMenu(e: any) {
    let subMenus, arrows;

    this.arrowCollapse = e.target.querySelector(".menu-dropdown__item__arrow");

    this.toggler = e.target;

    if (e.target.className === 'menu-dropdown__item') {


      if (this.toggler.lastChild.classList.contains("--active")) {
        this.toggler.lastChild.classList.remove("--active");
        this.arrowCollapse.style.transform = "rotate(360deg)";
      } else {

        subMenus = document.getElementsByClassName("menu-dropdown__item__content")
        for (const subMenu of subMenus) {
          subMenu.classList.remove("--active");
        }
        arrows = document.getElementsByClassName("menu-dropdown__item__arrow")
        for (const arrow of arrows) {
          arrow.style.transform = "rotate(360deg)";;
        }
        this.toggler.lastChild.classList.add("--active");
        this.arrowCollapse.style.transform = "rotate(90deg)";
      }

    }


  }

  secundarySideMenuManual(e, item) {
    let contentManual, links;

    contentManual = document.getElementsByClassName("content-manual");

    for (const content of contentManual) {
      content.style.display = 'none';
    }

    links = document.getElementsByClassName("link-sub-menu");

    for (const link of links) {
      link.className = link.className.replace(" active", "");
    }

    document.getElementById(item).style.display = "block";
    e.currentTarget.className += " active";
  }

}
