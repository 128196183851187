import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-blast",
  templateUrl: "./blast.component.html",
  styleUrls: ["./blast.component.scss"],
})
export class BlastComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    let header = document.getElementById("header");
    header.classList.remove("--not-found");
    header.style.display = "flex";
  }
}
