<section class="section-pages">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5 mb-4 mb-md-5">
        <img
          class="px-5 px-md-0"
          src="assets/images/infinitesimal-page.png"
          alt=""
        />
      </div>

      <div class="col-12 col-md-6 col-lg-7 pl-md-4 pl-lg-5">
        <div class="mb-3 pt-md-3 pages-logo">
          <img
            class="logo-infin"
            src="assets/images/infinitesimal-logo@2x.png"
            srcset="
              assets/images/infinitesimal-logo-small.png  720w,
              assets/images/infinitesimal-logo@2x.png    2000w
            "
            sizes="(max-width: 720px) 720px, (max-width: 1200px) 2000px"
            alt=""
          />
        </div>

        <p class="mb-0 mb-md-4">
          {{ "infinitesimal.text" | translate }}
        </p>
        <div class="btn-container">
          <a
            class="btn mb-0 mb-md-5"
            href="docs/infinitesimal.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Whitepaper</span></a
          >
        </div>
      </div>
    </div>

    <div class="row pb-5">
      <div class="col-12">
        <p class="pb-lg-4">
          {{ "infinitesimal.textTwo" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
