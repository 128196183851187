<section class="section-pages">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 col-lg-5 mb-4 mb-md-5">
        <img
          class="px-5 px-md-0"
          src="assets/images/rgt-prime-page.png"
          alt=""
        />
      </div>

      <div class="col-12 col-md-6 col-lg-7 pl-md-4 pl-lg-5">
        <div class="mb-3 mb-md-0 pages-logo">
          <img
            style="max-width: 83px"
            src="assets/images/rgt-prime-logo.png"
            alt=""
          />
        </div>

        <p class="mb-0 mb-md-4">
          {{ "rgtPrime.text" | translate }}
        </p>
        <div class="btn-container">
          <a
            class="btn mb-0 mb-md-5"
            href="https://blastnetwork.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ "rgtPrime.btn" | translate }}
            <span> {{ "rgtPrime.btnTwo" | translate }} </span>
          </a>
        </div>
      </div>
    </div>

    <div class="row pb-5">
      <div class="col-12">
        <p class="pb-4">
          {{ "rgtPrime.textTwo" | translate }}
        </p>

        <p class="pb-lg-4">
          {{ "rgtPrime.textThree" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
