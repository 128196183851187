import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CoinpaymentPdfComponent } from 'src/app/components/modal/handbook/coinpayment-pdf/coinpayment-pdf.component';

@Component({
  selector: 'app-payment-system',
  templateUrl: './payment-system.component.html',
  styleUrls: ['./payment-system.component.scss']
})
export class PaymentSystemComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }


  openDialogCoinpaymentPDF(): void {
    document.getElementById("header").style.display = "none";
    const dialogRef = this.dialog.open(CoinpaymentPdfComponent, {
      panelClass: "my-dialog-pdf",
      width: '80%',
      height: '60vw'
    });

    dialogRef.afterClosed().subscribe((res) => { document.getElementById("header").style.display = "block"; });
  }

}
