import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TermsAndconditionsComponent } from "../../modal/terms-andconditions/terms-andconditions.component";
import { PrivacyPolicyModalComponent } from "../../modal/privacy-policy-modal/privacy-policy-modal.component";
import { FrequentQuestionsComponent } from "../../modal/frequent-questions/frequent-questions.component";

declare const apg_bf044ca1_cc16_441e_88ce_6f506b905484: any;
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  d = document;
  querie;
  currentYear: number = new Date().getFullYear();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
   /*  apg_bf044ca1_cc16_441e_88ce_6f506b905484.init(); */
  }

  scrollTop() {
    this.d.body.scrollTop = 0;
    this.d.documentElement.scrollTop = 0;
  }

  openDialogTerms(): void {
    const dialogRef = this.dialog.open(TermsAndconditionsComponent, {
      panelClass: "my-dialog",
    });

    dialogRef.afterClosed().subscribe((res) => { });
  }

  openDialogPrivacy(): void {
    const dialogRef = this.dialog.open(PrivacyPolicyModalComponent, {
      panelClass: "my-dialog",
    });
    dialogRef.afterClosed().subscribe((res) => { });
  }

  openDialogQuestions(): void {
    const dialogRef = this.dialog.open(FrequentQuestionsComponent, {
      panelClass: "my-dialog",
    });
    dialogRef.afterClosed().subscribe((res) => { });
  }
}
