<div class="col col-md-6 col-lg-4">
  <div (click)="openDialogCoinpaymentPDF()" class="manual__external-item">
    <div class="manual__external-item__header">
      <img class="coin" src="assets/images/handbook/external-platforms/coinpayment.png" alt="" />
      <h3 class="texts__title --manual">CoinPayments</h3>
    </div>

    <div class="manual__external-item__header">
      <p class="texts__paragraphs --manual">
        This manual will guide you through the entire setup process of your CoinPayments account in your casino, allowing your clients to deposit and make withdrawals
      </p>
    </div>
  </div>
</div>

<div class="col col-md-6 col-lg-4">
  <div (click)="openDialogCasinoSupportPDF()" class="manual__external-item">
    <div class="manual__external-item__header">
      <img src="assets/images/handbook/external-platforms/Cs.png" alt="" />
      <h3 class="texts__title --manual">Casino Support</h3>
    </div>

    <div class="manual__external-item__header">
      <p class="texts__paragraphs --manual">
        This manual explain how to register and use casinosupport platform in order to solve all the questions you may have regards your casino
      </p>
    </div>
  </div>
</div>