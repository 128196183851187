<!-- <h2 mat-dialog-title>Terms & Conditions</h2> -->
<button class="close" mat-button mat-dialog-close>X</button>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <div class="row mb-4 mb-lg-5">
      <div class="col-12">
        <h2 class="texts__title --about mb-4 mb-lg-3">
          1. What's the recommended bankroll required to start a casino?
        </h2>

        <p class="texts__paragraphs">
          The amount of startup capital you will need to run your casino depends
          on several factors. What size bet limits are you going to offer? How
          many employees are you going to have? What’s your marketing plan? If
          you want to get started with small limits, aggressively monitor your
          players and run the business yourself, you should start with at least
          $10,000. The more money you’re willing to invest, the more money you
          can potentially make.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          2. Are there any additional fees?
        </h2>

        <p class="texts__paragraphs">
          The base $9995 setup fee includes a white label online casino with
          sportsbook, with cryptocurrency payment processing through
          Coinpayments or CoinsPaid (16 cryptocurrencies included with initial
          setup). We support 15 popular e-wallets and different payment methods.
          Pending availability, each of these can be enabled for a small
          additional fee.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">Provider Fees</h2>

        <p class="texts__paragraphs">
          All games, including Slots, Virtual Casino, Prematch and Live Sports
          Betting, Live Casino, and Virtual Sports are charged either a 15% or
          20% Provider Fee, based on GGR (GGR is defined as the total amount bet
          minus the total amount won). Provider Fee is due and paid weekly every
          Monday. Provider Fees carry a $495 monthly minimum.
        </p>

        <!-- <h2 class="texts__title --about mb-4 mb-lg-3">
          Platform Fees
        </h2>

        <table style="width: 100%;" class="mb-3">
          <tr>
            <td>$0 - $10,000 GGR</td>
            <td>10.00%</td>
          </tr>
          <tr>
            <td>$10,001 - $25,000 GGR</td>
            <td>7.50%</td>
          </tr>
          <tr>
            <td>$25,001 - $50,000 GGR</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>$50,001+ GGR</td>
            <td>2.50%</td>
          </tr>
        </table>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Processing Fees
        </h2>

        <table style="width: 100%;" class="mb-3">
          <tr>
            <td>$0 - $10,000</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>$10,001 - $25,000</td>
            <td>1.50%</td>
          </tr>
          <tr>
            <td>$25,001 - $50,000</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>$50,001+</td>
            <td>0.50%</td>
          </tr>
        </table> -->

        <h2 class="texts__title --about mb-4 mb-lg-3">
          3. Can it be marketed to the USA?
        </h2>

        <p class="texts__paragraphs">
          If you want to market to the USA market, you will need to hold a
          gaming license within each state you want to operate in. If you do not
          wish to obtain a gaming license, then no, you will not be able to
          market to USA players. Our platform automatically blocks USA players
          from being able to play real money games and bet on sports.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          4. Can skins set wagering limits?
        </h2>

        <p class="texts__paragraphs">
          For your protection, wagering limits are set by RGT universally with
          our proprietary risk algorithm, combining years of data science with
          gaming intuition.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          5. Am I allowed to own and operate an online casino being in the USA?
        </h2>

        <p class="texts__paragraphs">
          We can not provide legal advice as to gambling regulations in your
          jurisdiction. It’s recommended for you to consult with a reputable
          attorney.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          6. How are monthly fees invoiced and paid?
        </h2>

        <p class="texts__paragraphs">
          Because you hold all of your funds, you will be invoiced monthly for
          your hosting, platform, and processing fees. Content fees are due
          every Monday. You can pay a deposit for your content fees so that your
          weekly content fee is automatically deducted from this amount, rather
          than manually paying this amount each Monday.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          7. Who maintains control of player deposits?
        </h2>

        <p class="texts__paragraphs">
          You maintain custody of your player’s deposits. Using our checkout
          system, we integrate your accounts with the various supported payment
          methods. For example, when a player deposits with Bitcoin, that
          Bitcoin is sent directly to either your CoinPayments or CoinsPaid
          account.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          8. What if I have a player who keeps winning? Do I have control over
          accepting their action?
        </h2>

        <p class="texts__paragraphs">
          Absolutely. They’re your players; it’s your business. You can choose
          which players aren’t a fit for your brand. Our Risk Management
          department will also advise you when certain players are recognized as
          “sharp” or unprofitable players.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          9. Does my casino come with an affiliate program?
        </h2>

        <p class="texts__paragraphs">
          Yes. You have a built-in affiliate program so you can begin recruiting
          affiliates to drive traffic and signups to your casino. Since this is
          your business, you will be responsible for managing the affiliate
          program, including paying out your affiliates and handling any
          affiliate support. You control when you pay your affiliates, and what
          percentage of revenue-sharing commission models you want to pay.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          10. Who handles customer support for my players?
        </h2>

        <p class="texts__paragraphs">
          It’s up to you to provide customer support for your players. However,
          we do offer a complete 24/7 customer service solution, where we
          provide you fully-trained bilingual (English and Spanish) agents to
          handle all of your support needs. Contact your Account Manager for
          more details.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          11. What if I want to add a different deposit or withdrawal payment
          method to my casino?
        </h2>

        <p class="texts__paragraphs">
          No problem! Your casino comes with a Coinpayments or CoinsPaid payment
          integration that each supports 16 of the most popular
          cryptocurrencies, including Bitcoin and Ethereum. If you want to add
          other supported payment methods, each additional integration costs
          $200. If it’s a custom payments integration, there is a $1,500 annual
          fee that covers the integration and maintenance.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          12. What payment methods do you accept for a turnkey casino and the
          monthly fees?
        </h2>

        <p class="texts__paragraphs">
          At this time, we accept 16 of the most popular cryptocurrencies.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          13. How are withdrawals processed?
        </h2>

        <p class="texts__paragraphs">
          Your dashboard makes it really simple to approve or deny withdrawals.
          Because your cashier system is already integrated with CoinPayments,
          withdrawals are processed through your CoinPayments account as soon as
          you process each withdrawal.
        </p>

        <p class="texts__paragraphs">
          As you can see in the screenshot below, processing a withdrawal is
          easy; simply login to the pending withdrawals section of your
          dashboard and choose which withdrawals to approve or reject. It’s
          really that simple.
        </p>

        <img src="assets/images/faq-1.png" alt="" />

        <h2 class="texts__title --about mb-4 mb-lg-3">
          14. Do I need to have a bank account for this?
        </h2>

        <p class="texts__paragraphs">
          We can’t advise you on legal or financial matters like this. It’s
          recommended to consult an attorney in your jurisdiction.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          15. Is this a white label or turnkey casino?
        </h2>

        <p class="texts__paragraphs">
          This is a turnkey casino operation. You’re buying your own casino and
          you have to run the business. We provide to you the software, payment
          processing, hosting, and Risk Management. You have to handle
          marketing, customer service, paying your players, etc. It’s important
          to understand that you’re buying a real business. This is not a white
          label affiliate solution.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          16. Will I have full analytics on my players?
        </h2>

        <p class="texts__paragraphs">
          Of course. You’ll know exactly who your profitable (and unprofitable)
          players are. You’ll know how much they deposit and withdraw, what they
          play and bet on, whether they take bonuses, etc. You have full control
          over your players. It’s your casino!
        </p>
        <!--
        <p class="texts__paragraphs">
          The entire liability of RGT to the Client in respect of any claim
          whatsoever or breach of this Agreement, whether or not arising out of
          negligence, shall be limited to the charges paid for the Services
          under this Agreement in respect of which the breach has arisen.
        </p> -->

        <h2 class="texts__title --about mb-4 mb-lg-3">
          17. What if I don’t have a logo?
        </h2>

        <p class="texts__paragraphs">
          We can take care of that for you. We provide logo design and
          development services for only $200.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          18. Do you offer a gaming license? Is a license required?
        </h2>

        <p class="texts__paragraphs">
          We don’t require a license to use our platform, but some game and
          payment providers do require a license (such as Neteller, Skrill and
          others). For $7,500, you can get a Curacao gaming license approved and
          issued in approximately one week.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          19. Do I need to setup a company and bank account for this?
        </h2>

        <p class="texts__paragraphs">
          That’s completely your decision if you wish to set up a company and
          bank account. We are able to establish corporations at competitive
          rates in multiple gaming-friendly jurisdictions. Alternatively, we do
          need to know which entity you will be using for the RGT licensing
          agreement, which must be executed prior to launch.
        </p>

        <p class="texts__paragraphs">
          Banking can be difficult, but certainly possible with a gaming
          license. However, if you limit player deposits and withdrawals to just
          cryptocurrencies, you shouldn’t need a bank account.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          20. How is payment processing handled?
        </h2>

        <p class="texts__paragraphs">
          Your casino comes installed with 16 different cryptocurrencies in your
          cashier, through an integration with CoinPayments.com. You will need
          to create an account at CoinPayments and provide your API key in your
          dashboard so that your casino can process deposits and withdrawals
          through CoinPayments. You control the funds at all times.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          21. What currency will my casino be in?
        </h2>

        <p class="texts__paragraphs">
          After you order your casino, your Account Manager will ask you which
          base currency you want your player deposits, withdrawals, balances and
          gameplay to be in. We support all the major currencies and mBTC
          (millibitcoin).
        </p>

        <p class="texts__paragraphs">
          Additionally, we can integrate with several other payment providers
          for a $200 annual fee (for wallet support and maintenance), including
          Visa and Mastercard through Moonpay and popular e-wallets like Payeer,
          Perfect Money, and Skrill.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          22. What about KYC (Know Your Customer)?
        </h2>

        <p class="texts__paragraphs">
          If you or your jurisdiction requires it, it’s your responsibility to
          collect KYC documentation from your players. Sensitive player data is
          not stored on our servers, and we don’t provide an interface for
          players to upload their documentation.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          23. What does my dashboard look like?
        </h2>

        <p class="texts__paragraphs">
          Your dashboard gives you everything you need to run your casino
          business, including full reporting, complete player data, casino
          customization, and more. Check out the following screenshots of what
          your dashboard looks like:
        </p>

        <img src="assets/images/faq-2.png" alt="" />
        <img src="assets/images/faq-8.png" alt="" />
        <img src="assets/images/faq-4.png" alt="" />
        <img src="assets/images/faq-5.png" alt="" />
        <img src="assets/images/faq-6.png" alt="" />
        <img src="assets/images/faq-7.png" alt="" />
        <img src="assets/images/faq-3.png" alt="" />
      </div>
    </div>
  </div>
</mat-dialog-content>
