<section class="headings --internal-pages --bg-two">
  <h1>
    gamecloud
    <b>API</b>
  </h1>

  <p>
    {{ "gamecloudApi.paragraphHeader" | translate }}
  </p>
</section>

<section class="section mt-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6 px-5 d-none d-md-block">
        <img class="img-api-one mb-4" src="assets/images/api-img-one.png" alt="" />
      </div>

      <div class="col-12 col-lg-6">
        <h2 class="texts__title mb-4 mb-md-2">
          {{ "gamecloudApi.casinoIntegrationTitle" | translate }}
        </h2>
        <p class="texts__paragraphs mb-5">
          {{ "gamecloudApi.casinoIntegrationText" | translate }}
        </p>

        <form [formGroup]="form" class="section__form px-5 px-md-0">
          <div class="row justify-content-center mb-0">
            <div class="col-12 col-lg-6">
              <div class="Input">
                <input formControlName="name" type="text" id="input" class="Input-text" placeholder="{{ 'form.firstName' | translate }}" />
                <div class="mt-2" *ngIf="form.get('name').errors && form.get('name').touched">
                  <p class="texts__error" *ngIf="form.get('name').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('name').hasError('maxlength')">
                    {{ "form.maximun40Characters" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('name').hasError('pattern')">
                    {{ "form.onlyLetters" | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="Input">
                <input formControlName="lastName" type="text" id="input" class="Input-text" placeholder="  {{ 'form.lastName' | translate }}" />
                <div class="mt-2" *ngIf="
                    form.get('lastName').errors && form.get('lastName').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('lastName').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('lastName').hasError('maxlength')">
                    {{ "form.maximun40Characters" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('lastName').hasError('pattern')">
                    {{ "form.onlyLetters" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-0">
            <div class="col-12 col-lg-6">
              <div class="Input">
                <input formControlName="email" type="email" id="input" class="Input-text" placeholder="{{ 'form.email' | translate }}" />
                <div class="mt-2" *ngIf="form.get('email').errors && form.get('email').touched">
                  <p class="texts__error" *ngIf="form.get('email').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('email').hasError('email')">
                    {{ "form.invalidEmail" | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="Input">
                <input formControlName="phone" type="text" id="input" class="Input-text" placeholder="  {{ 'form.phone' | translate }}" />
                <div class="mt-2" *ngIf="form.get('phone').errors && form.get('phone').touched">
                  <p class="texts__error" *ngIf="form.get('phone').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('phone').hasError('pattern')">
                    {{ "form.invalidNumber" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-0">
            <div class="col-12">
              <div class="Input">
                <input formControlName="domain" type="text" id="input" class="Input-text" placeholder=" {{ 'form.url' | translate }}" />
                <div class="mt-2" *ngIf="
                    form.get('domain').errors && form.get('domain').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('domain').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="Input">
                <textarea formControlName="message" id="input" class="Input-text" rows="5" placeholder="  {{ 'form.message' | translate }}"></textarea>
                <div class="mt-2" *ngIf="
                    form.get('message').errors && form.get('message').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('message').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button (click)="onFormSubmit($event)" type="submit" class="btn sub">
            {{ "form.btnSubmit" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</section>

<section class="section bg-d">
  <div class="container">
    <div class="row align-items-center mb-2">
      <div class="col-12">
        <div class="tab">
          <button class="tablinks" (click)="openTab($event, 'one')" id="defaultOpen">
            {{ "gamecloudApi.tabTitle" | translate }}
          </button>
          <button class="tablinks px-2" (click)="openTab($event, 'two')">
            {{ "gamecloudApi.tabTitleTwo" | translate }}
          </button>
          <button class="tablinks" (click)="openTab($event, 'three')">
            {{ "gamecloudApi.tabTitleThree" | translate }}
          </button>
        </div>

        <!-- Tab content -->
        <div id="one" class="tabcontent">
          <div class="row">
            <div class="col-12 col-md-6">
              <h2 class="texts__title tab-title mb-3 mt-3">
                {{ "gamecloudApi.tabSubTitle" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-3 mb-lg-5">
                {{ "gamecloudApi.tabText" | translate }}
              </p>
            </div>
            <div class="col-12 col-md-6">
              <img src="assets/images/tab-one.png" alt="" />
            </div>
          </div>
        </div>

        <div id="two" class="tabcontent">
          <div class="row">
            <div class="col-12 col-md-6">
              <h2 class="texts__title tab-title mb-3 mt-3">
                {{ "gamecloudApi.tabSubTitleTwo" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-3 mb-lg-5">
                {{ "gamecloudApi.tabTextTwo" | translate }}
              </p>
            </div>
            <div class="col-12 col-md-6">
              <img src="assets/images/tab-two.png" alt="" />
            </div>
          </div>
        </div>

        <div id="three" class="tabcontent">
          <div class="row">
            <div class="col-12 col-md-6">
              <h2 class="texts__title tab-title mb-3 mt-3">
                {{ "gamecloudApi.tabSubTitleThree" | translate }}
              </h2>
              <p class="texts__paragraphs tab-p mb-3 mb-lg-5">
                {{ "gamecloudApi.tabTextThree" | translate }}
              </p>
            </div>
            <div class="col-12 col-md-6">
              <img src="assets/images/tab-three.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
