import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: 'app-casino-support',
  templateUrl: './casino-support.component.html',
  styleUrls: ['./casino-support.component.scss']
})
export class CasinoSupportComponent implements OnInit {
  pdf = "docs/casino-support-doc-en.pdf";
  constructor(public dialogRef: MatDialogRef<CasinoSupportComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
