import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-our-customers",
  templateUrl: "./our-customers.component.html",
  styleUrls: ["./our-customers.component.scss"],
})
export class OurCustomersComponent implements OnInit {
  d = document;
  form: FormGroup;

  constructor(public router: Router, private formBuilder: FormBuilder, public translate: TranslateService) {
    this.buildForm();
  }

  ngOnInit() {
    let header = document.getElementById("header");
    header.classList.remove("--not-found");
    header.style.display = "flex";
  }
  private buildForm() {
    this.form = this.formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(/^[a-zA-Z_-\s]*$/),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(/^[a-zA-Z_-\s]*$/),
        ],
      ],
      email: ["", [Validators.required, Validators.email]],

      phone: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/),
        ],
      ],
      domain: ["", [Validators.required]],
      message: ["", [Validators.required]],
    });

    this.form.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  onFormSubmit(e) {
    console.log(e);
    // e.target.textContent = this.translate.getTranslation("form.btnSubmit");
    if (this.form.invalid === true) {
      this.form.markAllAsTouched();
      // e.target.textContent = this.translate.getTranslation("form.btnSubmit");
      return;
    } else {
      // e.target.textContent = this.translate.getTranslation("form.btnSubmit");
    }
  }

  scrollTop() {
    this.router.navigate(["/gamecloud"]);
    this.d.body.scrollTop = 0;
    this.d.documentElement.scrollTop = 0;
  }
}
