<!-- <h2 mat-dialog-title>Terms & Conditions</h2> -->
<button class="close" mat-button mat-dialog-close>X</button>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <div class="row mb-4 mb-lg-5">
      <div class="col-12">
        <h2 class="texts__title --about mb-4 mb-lg-3">
          PRIVACY POLICY
        </h2>

        <p class="texts__paragraphs">
          This Privacy Policy (“Policy”) describes how and why we collect,
          process, and utilise any information that you share with us when using
          the website rgt.com (“Website”) or using other services provided by
          Royal Gaming Technology (“Services”).
        </p>

        <p class="texts__paragraphs">
          This Policy applies where we are acting as a data controller with
          respect to information pertaining to Website visitors and Services
          users.
        </p>

        <p class="texts__paragraphs">
          We are committed to protecting your privacy and safeguarding your
          data. Any information by which you can be identified while using the
          Website or the Services (“Personal Information”) will only be used in
          accordance with this Policy.
        </p>

        <p class="texts__paragraphs">
          By using our Website and/or Services, you confirm your consent with
          the Privacy Policy.
        </p>

        <p class="texts__paragraphs">
          We may periodically update this Policy by publishing a new version on
          our Website. Please check this page occasionally to make sure you are
          comfortable with any changes to the Policy.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Information we collect from you
        </h2>

        <p class="texts__paragraphs">
          We can obtain your Personal Information via a number of sources. This
          includes, without limitation:
        </p>

        <p class="texts__paragraphs">
          a) Information you provide us with when filling forms on our Website
        </p>

        <p class="texts__paragraphs">
          This includes your personal details such as name, surname, email
          address, phone number, messenger ID, resume, etc. This information is
          obtained through its voluntary submission to our system and may be
          processed for the purposes of answering your queries on our Website or
          Services, providing our Services, operating our Website, ensuring the
          security of our Website and Services, maintaining backups of our
          databases and communicating with you. The legal basis for such
          processing is your consent.
        </p>

        <p class="texts__paragraphs">
          b) Information we obtain based on your use of our Website or Services
        </p>

        <p class="texts__paragraphs">
          This includes which content you view, which products or games you are
          interested in, your dynamic IP address, browser type and operating
          system, referral source, etc., as well as information about the
          timing, frequency and pattern of your use of our Websites or Services.
          This usage data may be processed for analysis purposes. The legal
          basis for such processing is monitoring and improving our Website and
          Services.
        </p>

        <p class="texts__paragraphs">
          c) Records and correspondence
        </p>

        <p class="texts__paragraphs">
          We may keep correspondence from you to us for record-keeping purposes
          and/or process your Personal Information to satisfy our legal
          obligations, or where we have a legitimate reason for doing so in
          accordance with this Policy. We may process any of your Personal
          Information where necessary for the establishment or defence of legal
          claims. The legal basis for such processing is protection of our legal
          rights, your legal rights and the legal rights of others.
        </p>

        <p class="texts__paragraphs">
          d) Cookies
        </p>

        <p class="texts__paragraphs">
          Cookies are small text files that are sent to your device when you
          access our Website and/or use our Services. Please refer to the
          separate Cookie Policy section below for details on cookies and your
          choices about them.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Who we share your data with
        </h2>

        <p class="texts__paragraphs">
          We may disclose your Personal Information to any member of our group
          of companies (i.e. our parent companies, their parent companies and
          all of the subsidiaries of such companies) when reasonably necessary
          for the purposes set out in this Policy.
        </p>

        <p class="texts__paragraphs">
          We may disclose your Personal Information to our professional advisers
          and business partners when reasonably necessary in order to manage
          risks, obtain professional advice, or to establish and defend our
          legal claims, whether in court or in an out-of-court procedure.
        </p>

        <p class="texts__paragraphs">
          Any our employees or partners who are associated with processing of
          your Personal Information have signed confidentiality agreements to
          respect the confidential nature of your information pursuant to
          applicable data protection and privacy laws. We never sell or rent
          your Personal Information to third parties.
        </p>

        <p class="texts__paragraphs">
          In addition to the above, we may disclose your Personal Information
          when this is necessary for compliance with a legal obligation to which
          we are subject.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Data retention
        </h2>

        <p class="texts__paragraphs">
          We will ensure that any personal data we process for any purpose shall
          not be kept for longer than necessary for that purpose. We may retain
          your personal data where such retention is necessary to comply with a
          legal obligation to which we are subject, or in order to protect your
          legitimate interests or the vital interests of another natural person.
          We may also keep derivatives of your information for analytics
          purposes where no automated decision-making is involved.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Cookie policy
        </h2>

        <p class="texts__paragraphs">
          We use cookies and similar tracking technologies to help us understand
          how people use our Website and Services so that we can keep improving
          them.
        </p>

        <p class="texts__paragraphs">
          Cookies are small text files that are stored on your computer and
          saved by your browser. They do not harm your computer and do not
          contain any viruses. Cookies help make our website more user-friendly,
          efficient, and secure.
        </p>

        <p class="texts__paragraphs">
          Most of the cookies we use are so-called “session cookies”. They are
          automatically deleted after your visit. Other cookies remain in your
          device’s memory until you delete them. These cookies make it possible
          to recognize your browser when you next visit the site.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Cookies used by our service providers
        </h2>

        <p class="texts__paragraphs">
          In addition to our own cookies, we use cookies from various reputable
          companies that let us analyse how the Websites and the Services are
          used. This helps us optimise our Website and Services to deliver the
          best possible experience.
        </p>

        <p class="texts__paragraphs">
          We use Google Analytics, a web analytics service from Google Inc.,
          1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Google
          Analytics gains information about websites use through cookies.
          Further information may be found here
          (https://support.google.com/analytics/answer/6004245), including a
          link to Google’s privacy policy
          (https://policies.google.com/privacy?hl=en).
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Consent to cookies
        </h2>

        <p class="texts__paragraphs">
          If you choose to use the Website and/or our Services without blocking,
          disabling or opting out of cookies, you confirm your consent to our
          use of these technologies.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          How to opt-out from cookies?
        </h2>

        <p class="texts__paragraphs">
          Modern web browsers usually allow you to refuse to accept cookies and
          to delete cookies. You can also configure your browser to inform you
          about the use of cookies so that you can decide on a case-by-case
          basis whether to accept or reject a cookie.
        </p>

        <p class="texts__paragraphs">
          Please note that blocking all cookies will have a negative impact on
          the usability of many websites and if you block cookies, you use of
          our Website or Services may be restricted.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Your rights
        </h2>

        <p class="texts__paragraphs">
          Below is an overview of the main rights you have under Data Protection
          Law. This is not an exhaustive list. You can learn more by reading the
          relevant guidance from the regulatory authorities for a full
          explanation of these rights.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Revocation of your consent
        </h2>

        <p class="texts__paragraphs">
          Many data processing operations are only possible with your express
          consent. You may revoke your consent to processing of your personal
          data at any time with future effect. An informal email making this
          request is sufficient. Personal data processed before we receive your
          request may still be legally processed.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Rights to information, blocking, deletion
        </h2>

        <p class="texts__paragraphs">
          As permitted by law, you have the right to be provided with
          information about any of your personal data that is stored, as well as
          its origin, the recipient and the purpose for which it has been
          processed. You also have the right to have this data corrected or
          deleted. You can contact us at any time if you have further questions
          on the topic of personal data.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Right to data portability
        </h2>

        <p class="texts__paragraphs">
          You have the right to have data we process based on your consent or in
          fulfillment of a contract delivered to you or to a third party in a
          standard, machine-readable format.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Right to file complaints with regulatory authorities
        </h2>

        <p class="texts__paragraphs">
          If there has been a breach of data protection legislation, the person
          affected may file a complaint with the competent regulatory
          authorities, in particular in the Member State of his/her habitual
          residence, place of work or place of an alleged infringement.
        </p>

        <p class="texts__paragraphs">
          Please note that any mandatory statutory provisions, especially those
          regarding mandatory data retention periods, remain unaffected by
          provisions above.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Security
        </h2>

        <p class="texts__paragraphs">
          We employ a range of security mechanisms and best practices to protect
          your information from unauthorised access, unlawful processing,
          accidental loss, destruction and damage.
        </p>

        <p class="texts__paragraphs">
          This Website uses SSL encryption, which means that the data you
          transfer to us via the Website cannot be read by third parties at the
          time of transfer. You can recognise an encrypted connection in your
          browser’s address line when it reads “https://” and the Lock icon is
          displayed on the address bar.
        </p>

        <p class="texts__paragraphs">
          While we do our part, we remind you that you should also take personal
          steps to ensure the security of your information, both physically and
          electronically. Common measures include: running Anti-Virus software
          and keeping it up to date; regularly updating operating systems, web
          browsers and other applications; ensuring your computer or mobile
          device is password protected; using strong passwords for all logins.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Contacting us
        </h2>

        <p class="texts__paragraphs">
          If you have any questions about this Policy, or would like to exercise
          any of your rights in relation to your personal data, you should
          contact us by email at support@rgt.com.
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>
