<section class="not-found">

  <div class="not-found__logo">
    <a [routerLink]="['/']"><img src="assets/images/log-rgt-not-found.png" alt=""> </a>
  </div>

  <h1 class="not-found__title"><span>404</span></h1>

  <h2 class="not-found__sub-title"> <span>Something went wrong</span> </h2>

  <p class="not-found__text">Page Not Found</p>
  <a [routerLink]="['/']"> <img class="not-found__btn" src="assets/images/btn-not-found.png" alt=""></a>

  <ul class="not-found__nav">
    <li><a [routerLink]="['']">HOME</a></li>
  </ul>
</section>
