import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CasinoSupportComponent } from '../../../modal/handbook/casino-support/casino-support.component';
import { CoinpaymentPdfComponent } from '../../../modal/handbook/coinpayment-pdf/coinpayment-pdf.component';

@Component({
  selector: 'app-external-platforms',
  templateUrl: './external-platforms.component.html',
  styleUrls: ['./external-platforms.component.scss']
})
export class ExternalPlatformsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }


  openDialogCasinoSupportPDF(): void {
    document.getElementById("header").style.display = "none";
    const dialogRef = this.dialog.open(CasinoSupportComponent, {
      panelClass: "my-dialog-pdf",
      width: '80%',
      height: '60vw'
    });

    dialogRef.afterClosed().subscribe((res) => { document.getElementById("header").style.display = "block"; });
  }

  openDialogCoinpaymentPDF(): void {
    document.getElementById("header").style.display = "none";
    const dialogRef = this.dialog.open(CoinpaymentPdfComponent, {
      panelClass: "my-dialog-pdf",
      width: '80%',
      height: '60vw'
    });

    dialogRef.afterClosed().subscribe((res) => { document.getElementById("header").style.display = "block"; });
  }

}
