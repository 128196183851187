<h2 class="texts__title --manual tab-title mb-3">
  Favicon
</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  1. To update your Favicon go to Dashboard > Skin > Settings
</p>

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  2. Select the button "Choose File" to select your icon file. Remember that the right dimensions must be 40x40px.
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/skin/settings/favicon/favicon-one.png" alt=" subir logo" />

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  3.Press "Update Changes" button in the upper right corner to apply changes and then refresh page to be able to see them.
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/skin/settings/logo/logo-two.png" alt="" />