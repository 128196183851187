import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-gamecloud-page",
  templateUrl: "./gamecloud-page.component.html",
  styleUrls: ["./gamecloud-page.component.scss"],
})
export class GamecloudPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    let header = document.getElementById("header");
    header.classList.remove("--not-found");
    header.style.display = "flex";
  }
}
