<h2 class="texts__title --manual tab-title mb-3">Multilanguage</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  1.- To hide or show the language section on your casino go to Dashboard>Skin>Layout
</p>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/layout/layout-one.png" alt=" subir logo" />

<p class="texts__paragraphs --manual tab-p mb-3">
  2.- Select multilanguage Toggle to activate or deactivate it.
</p>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/layout/layout-two.png" alt="" />

<div class="row mb-0">
  <div class="col-12 col-lg-6">
    <p class="texts__paragraphs --manual tab-p mb-3">Skin</p>

    <img class="mb-4" src="assets/images/handbook/skin/select-plugins/layout/layout-three.png" alt="" />
  </div>

  <div class="col-12 col-lg-6">
    <p class="texts__paragraphs --manual tab-p mb-3">Off</p>

    <img class="mb-4" src="assets/images/handbook/skin/select-plugins/layout/layout-four.png" alt="" />

    <p class="texts__paragraphs --manual tab-p mb-3">On</p>

    <img class="mb-4" src="assets/images/handbook/skin/select-plugins/layout/layout-five.png" alt="" />
  </div>
</div>

<hr class="line-manual mb-4" />

<h2 class="texts__title --manual tab-title mb-3">Register</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  1.- To hide or show the register section on your casino go to Dashboard>Skin>Layout
</p>

<img class="mb-4" src="assets/images/handbook/skin/select-plugins/layout/layout-six.png" alt="" />

<p class="texts__paragraphs --manual tab-p mb-3">
  2.- Select Register Toggle to activate or deactivate it
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/skin/select-plugins/layout/layout-seven.png" alt="" />