<section class="headings --internal-pages --bg-two pb-4 pb-lg-5" style="height: auto">
  <h1 class="pt-1 pt-md-0">
    <span>{{ "ourCustomer.titleHeader" | translate }}
      <b>{{ "ourCustomer.titleHeaderTwo" | translate }}</b>
    </span>
  </h1>

  <p class="mb-4">
    {{ "ourCustomer.paragraphHeader" | translate }}
  </p>
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center mb-4 mt-5">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-lg-5 text-center">
        <h2 class="texts__title mb-3 mb-lg-4 text-center">
          {{ "ourCustomer.gamecloudPlatformTitle" | translate }}
        </h2>
      </div>

      <div class="col-12 col-lg-11 px-lg-0">
        <p class="texts__paragraphs px-lg-0 mb-lg-5">
          {{ "ourCustomer.gamecloudPlatformText" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-our-logos mb-lg-5">
  <div class="container">
    <div class="row no-gutters mb-3 pb-lg-5">
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-1">
        <a href="https://bitcoinsportsbetting.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/customer-one-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-md-1">
        <a href="https://cryptobet.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-two-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-1">
        <a href="https://tokenpoker.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/customer-three-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-md-1 pr-lg-0">
        <a href="https://bitcoinbaccarat.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-four-logo.png" alt="" /></a>
      </div>

      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://bitcoinbet.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-five-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-md-1 pb-1">
        <a href="https://btcbetting.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-six-logo.png" alt="" />
        </a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://goldcoincasino.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-seven-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-md-1 pr-lg-0">
        <a href="https://bitcoinblackjack.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-eight-logo.png" alt="" /></a>
      </div>

      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://astrocasino.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-nine-logo.png" alt="" />
        </a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-md-1 pb-1">
        <a href="https://edice.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-ten-logo.png" alt="" />
        </a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://bigpoker.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/customer-eleven-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-md-1 pr-lg-0">
        <a href="https://egyptiancasino.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-twenty-logo.png" alt="" /></a>
      </div>

      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://betbitcoin.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/customer-ten-three-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-md-1 pb-1">
        <a href="https://bitcoinroulette.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-ten-four-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://litecoincasino.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/customer-ten-five-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-md-1 pr-lg-0">
        <a href="https://bigslot.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-ten-six-logo.png" alt="" /></a>
      </div>

      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://elegantcasino.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-ten-seven-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-md-1 pb-1">
        <a href="https://titaniumcasino.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/customer-ten-eight-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pr-1 pb-1">
        <a href="https://unicorncasino.com/" target="_blank" rel="noopener noreferrer"><img src="assets/images/customer-ten-nine-logo.png" alt="" /></a>
      </div>
      <div class="col-6 col-md-4 col-lg-3 pb-1 pr-md-1 pr-lg-0">
        <a href="https://sunshinecasino.com/" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/customer-ten-ten-logo.png" alt="" /></a>
      </div>
    </div>
  </div>
</section>

<section class="section-our-form">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-7">
        <img class="px-4 mb-4 mb-lg-0 mt-lg-4" src="assets/images/api-laptop.png" alt="" />
      </div>

      <div class="col-12 col-lg-5 px-lg-0">
        <h2 class="texts__title mb-3">
          {{ "ourCustomer.interestedTitle" | translate }}
          <br class="d-none d-md-block" /> {{ "ourCustomer.interestedTitleTwo" | translate }}
        </h2>

        <p style="text-align: left" class="texts__paragraphs left mb-4">
          {{ "ourCustomer.interestedText" | translate }}
        </p>
        <form [formGroup]="form" class="section__form px-5 px-md-0">
          <div class="row no-gutters justify-content-center">
            <div class="col-12 col-md-6 pr-lg-3">
              <div class="Input">
                <input formControlName="firstName" type="text" id="input" class="Input-text" placeholder="{{ 'form.firstName' | translate }}" />
                <div class="mt-2" *ngIf="
                    form.get('firstName').errors &&
                    form.get('firstName').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('firstName').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('firstName').hasError('maxlength')">
                    {{ "form.maximun40Characters" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('firstName').hasError('pattern')">
                    {{ "form.onlyLetters" | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="Input">
                <input formControlName="lastName" type="text" id="input" class="Input-text" placeholder="{{ 'form.lastName' | translate }}" />
                <div class="mt-2" *ngIf="
                    form.get('lastName').errors && form.get('lastName').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('lastName').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('lastName').hasError('maxlength')">
                    {{ "form.maximun40Characters" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('lastName').hasError('pattern')">
                    {{ "form.onlyLetters" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center no-gutters mb-3">
            <div class="col-12 col-md-6 pr-lg-3">
              <div class="Input">
                <input formControlName="email" type="email" id="input" class="Input-text" placeholder="{{ 'form.email' | translate }}" />
                <div class="mt-2" *ngIf="form.get('email').errors && form.get('email').touched">
                  <p class="texts__error" *ngIf="form.get('email').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('email').hasError('email')">
                    {{ "form.invalidEmail" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="Input">
                <input formControlName="phone" type="text" id="input" class="Input-text" placeholder=" {{ 'form.phone' | translate }}" />
                <div class="mt-2" *ngIf="form.get('phone').errors && form.get('phone').touched">
                  <p class="texts__error" *ngIf="form.get('phone').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('phone').hasError('pattern')">
                    {{ "form.invalidNumber" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="Input">
                <textarea formControlName="message" id="input" class="Input-text" rows="5" placeholder="{{ 'form.message' | translate }}"></textarea>
                <div class="mt-2" *ngIf="
                    form.get('message').errors && form.get('message').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('message').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 left">
              <button (click)="onFormSubmit($event)" type="submit" class="btn">
                {{ "form.btnSubmit" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section class="section-our-two pb-4 pb-md-5">
  <div class="container">
    <div class="row mb-4 mb-lg-5 justify-content-center">
      <div class="col-12 col-sm-12 col-md-12 col-lg-11 mt-lg-5">
        <h2 class="texts__title mb-2 text-center">
          {{ "ourCustomer.createTitle" | translate }}
          <br class="d-md-none" /> {{ "ourCustomer.createTitleTwo" | translate }}
        </h2>
        <p class="texts__paragraphs px-1 px-lg-5">
          {{ "ourCustomer.createText" | translate }}
        </p>
      </div>
    </div>
    <div class="row no-gutters mb-3 align-items-center justify-content-center">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="create-your">
          <img src="assets/images/1.png" alt="" />

          <h3 class="mb-1">
            {{ "ourCustomer.createItemTitle" | translate }}
          </h3>
          <p class="px-4 px-lg-0 mb-3">
            {{ "ourCustomer.createItemText" | translate }}
          </p>
        </div>
      </div>
      <div class="col-lg-1 d-none d-lg-flex justify-content-center">
        <div class="d-flex justify-content-center align-items-center" style="margin-top: -150px">
          <img style="width: 10px" src="assets/images/arrow-right.svg" alt="" />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="create-your">
          <img class="mb-5 d-md-none" src="assets/images/arrow-down.svg" alt="" />
          <img src="assets/images/2.png" alt="" />

          <h3 class="mb-1">
            {{ "ourCustomer.createItemTitleTwo" | translate }}
          </h3>
          <p class="px-3 px-lg-0 mb-3">
            {{ "ourCustomer.createItemTextTwo" | translate }}
          </p>
        </div>
      </div>
      <div class="col-lg-1 d-none d-lg-flex justify-content-center">
        <div class="d-flex justify-content-lg-center align-items-center" style="margin-top: -145px">
          <img style="width: 10px" src="assets/images/arrow-right.svg" alt="" />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="create-your">
          <img class="mb-5 d-md-none" src="assets/images/arrow-down.svg" alt="" />
          <img clas src="assets/images/3.png" alt="" />

          <h3 class="mb-1">
            {{ "ourCustomer.createItemTitleThree" | translate }}
          </h3>
          <p class="px-3 px-lg-0 text-left">
            {{ "ourCustomer.createItemTextThree" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
