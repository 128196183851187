<header id="payment__page" [ngStyle]="{'background-image': 'url(../../../../assets/images/payment/header_bg.jpg)',  'background-size': 'cover' }" appBackgroundImageLoaded>
  <div class="container">
    <a routerLink="/" class="logo">
      <img class="d-none" src="assets/images/payment/logo.png" width="285" alt="RGT" />
    </a>
    <div class="row mt-5 mt-lg-4">
      <div class="col-md-6">
        <h1>
          {{ "gamecloudOne.headerTitle" | translate }}
          <b>{{ "gamecloudOne.headerTitleTwo" | translate }}</b>
          <b>{{ "gamecloudOne.headerTitleThree" | translate }}
            <span class="title-comment"></span></b>
        </h1>

        <ul>
          <li>
            {{ "gamecloudOne.headerList" | translate }}
          </li>
          <li>
            {{ "gamecloudOne.headerListTwo" | translate }}
          </li>
          <li>
            {{ "gamecloudOne.headerListThree" | translate }}
          </li>
        </ul>

        <img class="d-none d-md-block" src="assets/images/payment/header.png" alt="ONLINE casino" />
      </div>
      <div class="col-md-6 text-md-right d-flex flex-column">
        <form [formGroup]="formPayment" class="form" id="form">
          <div class="steps" id="step1">
            <span class="title">
              {{ "gamecloudOne.formTitle" | translate }}
              <b>
                {{ "gamecloudOne.formTitleTwo" | translate }}
              </b>
            </span>

            <div class="form-group user">
              <input formControlName="names" type="text" class="form-control" placeholder="{{ 'gamecloudOne.formTextName' | translate }}" (keydown.enter)="formValidOne($event)" />
              <div class="mt-2" *ngIf="
                  formPayment.get('names').errors &&
                  formPayment.get('names').touched
                ">
                <p class="texts__error" *ngIf="formPayment.get('names').hasError('required')">
                  {{ "form.requiredField" | translate }}
                </p>

                <p class="texts__error mt-2" *ngIf="formPayment.get('names').hasError('maxlength')">
                  {{ "form.maximun40Characters" | translate }}
                </p>

                <p class="texts__error mt-2" *ngIf="formPayment.get('names').hasError('pattern')">
                  {{ "form.onlyLetters" | translate }}
                </p>
              </div>
            </div>
            <div class="form-group email">
              <input formControlName="email" type="text" class="form-control" placeholder="{{ 'gamecloudOne.formTextEmail' | translate }}" (keydown.enter)="formValidOne($event)" />
              <div class="mt-2" *ngIf="
                  formPayment.get('email').errors &&
                  formPayment.get('email').touched
                ">
                <p class="texts__error" *ngIf="formPayment.get('email').hasError('required')">
                  {{ "form.requiredField" | translate }}
                </p>

                <p class="texts__error mt-2" *ngIf="formPayment.get('email').hasError('email')">
                  {{ "form.invalidEmail" | translate }}
                </p>
              </div>
            </div>

            <div class="form-check pt-3">
              <input type="checkbox" class="form-check-input" id="termsCheck" (click)="onCheckboxClick($event)" />

              <label class="form-check-label" for="termsCheck">
                {{ "gamecloudOne.formTerms" | translate }}
                <a (click)="openDialog()">
                  {{"gamecloudOne.formTermsTwo" | translate}}
                </a>
                {{ "gamecloudOne.formTermsThree" | translate }}
              </label>
            </div>
            <button class="btn link" (click)="formValidOne($event)" [disabled]="!checkboxEnabled">
              <span><img src="assets/images/payment/crown.svg" alt="Crown" /></span>
              {{ "gamecloudOne.formBtn" | translate }}
            </button>
            <form id="cpForm" [formGroup]="coinpayments" action="{{ actionURLCP }}" target="_blank">
              <input type="hidden" name="cmd" formControlName="cmd" value="{{ cmd }}" />
              <input type="hidden" name="reset" formControlName="reset" value="{{ reset }}" />
              <input type="hidden" name="merchant" formControlName="merchant" value="{{ merchant }}" />
              <input type="hidden" name="item_name" formControlName="item_name" value="{{ item_name }}" />
              <input name="invoice" type="hidden" formControlName="invoice" value="{{ orderNo }}" />
              <input type="hidden" name="currency" formControlName="currency" value="{{ currency }}" />
              <input type="hidden" name="amountf" formControlName="amountf" value="{{ amountf }}" />
              <input type="hidden" name="want_shipping" formControlName="want_shipping" value="{{ want_shipping }}" />
              <input type="hidden" name="success_url" formControlName="success_url" value="{{ sucessURL }}" />
              <input type="hidden" name="cancel_url" formControlName="cancel_url" value="{{ cancelURL }}" />
              <input type="hidden" name="ipn_url" formControlName="inp_url" value="{{ ipnURL }}" />

            </form>
          </div>
        </form>

        <div class="games">
          <span class="games__title">{{
            "gamecloudOne.featuringGames" | translate
          }}</span>

          <div class="games__list">
            <div class="games__list--item">
              <img src="assets/images/payment/games/game1.svg" alt="" />
            </div>
            <div class="games__list--item">
              <img src="assets/images/payment/games/game2.svg" alt="" />
            </div>
            <div class="games__list--item">
              <img src="assets/images/payment/games/game3.svg" alt="" />
            </div>
            <div class="games__list--item">
              <img src="assets/images/payment/games/game4.svg" alt="" />
            </div>
            <div class="games__list--item">
              <img src="assets/images/payment/games/game5.svg" alt="" />
            </div>
            <div class="games__list--item">
              <img src="assets/images/payment/games/game6.svg" alt="" />
            </div>
            <div class="games__list--item">
              <img src="assets/images/payment/games/game7.svg" alt="" />
            </div>
            <div class="games__list--item">
              <img src="assets/images/payment/games/game8.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="steps">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <h2 style="text-transform: none">
          {{ "gamecloudOne.goLive24Title" | translate }}
        </h2>
        <p class="">
          {{ "gamecloudOne.goLive24Text" | translate }}
        </p>
      </div>
    </div>

    <div class="steps__arrow">
      <img src="assets/images/payment/arrow.svg" alt="Arrow" />
    </div>

    <div class="row align-items-xl-center">
      <div class="col-md-6">
        <div class="poster">
          <img src="assets/images/payment/ready-to-go.png" alt="ready-to-go" />
        </div>
      </div>
      <div class="col-md-6">
        <h2 style="text-transform: none;"  class="steps__title mb-3 mt-2 mt-md-0">
          {{ "gamecloudOne.readyToGoTitle" | translate }}
        </h2>
        <h3 class="steps__title-marked mb-3">
          {{ "gamecloudOne.readyToGoSubTitle" | translate }}
        </h3>

        <p class="mb-3">
          {{ "gamecloudOne.readyToGoText" | translate }}
        </p>

        <ul>
          <li>{{ "gamecloudOne.readyToGoList" | translate }}</li>
          <li>{{ "gamecloudOne.readyToGoListTwo" | translate }}</li>
          <li>{{ "gamecloudOne.readyToGoListThree" | translate }}</li>
          <li>{{ "gamecloudOne.readyToGoListFour" | translate }}</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 order-2 order-md-1">
        <h2 style="text-transform: none;" class="steps__title mb-3 mt-md-0">
          {{ "gamecloudOne.casinoTableTitle" | translate }}
          ​</h2>
        <h3 class="steps__title-marked mb-3">
          {{ "gamecloudOne.casinoTableSubTitle" | translate }}
        </h3>

        <p class="mb-3">
          {{ "gamecloudOne.casinoTableText" | translate }}
        </p>

        <p>
          {{ "gamecloudOne.casinoTableTextTwo" | translate }}
        </p>

        <div class="steps__buttons mb-3 mb-md-0">
          <a (click)="scrollTop()" class="btn main anchor">
            <span><img src="assets/images/payment/crown.svg" alt="Crown" /></span> {{ "gamecloudOne.btnStar" | translate }}
          </a>
          <a href="https://cryptobet.com/" class="btn" target="_blank">
            {{ "gamecloudOne.btnDemo" | translate }}
          </a>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="line d-md-none"></div>
        <div class="poster text-right">
          <img src="assets/images/payment/game-art.png" alt="game-art" />
          <a data-fancybox="galleryCasino" href="assets/images/payment/casino/casino1.png" class="link">
            {{ "gamecloudOne.takeLook" | translate }}
          </a>
          <a data-fancybox="galleryCasino" href="assets/images/payment/casino/casino2.png" class="d-none"></a>
          <a data-fancybox="galleryCasino" href="assets/images/payment/casino/casino3.png" class="d-none"></a>
          <a data-fancybox="galleryCasino" href="assets/images/payment/casino/casino4.png" class="d-none"></a>
          <a data-fancybox="galleryCasino" href="assets/images/payment/casino/casino5.png" class="d-none"></a>
        </div>
      </div>
    </div>

    <div class="steps__arrow">
      <img src="assets/images/payment/arrow.svg" alt="Arrow" />
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="line d-md-none"></div>
        <div class="poster">
          <img src="assets/images/payment/live-casino.png" alt="live-casino" />
          <a data-fancybox="galleryLive" href="assets/images/payment/live/live1.png" class="link">
            {{ "gamecloudOne.takeLook" | translate }}
          </a>
          <a data-fancybox="galleryLive" href="assets/images/payment/live/live2.png" class="d-none"></a>
          <a data-fancybox="galleryLive" href="assets/images/payment/live/live3.png" class="d-none"></a>
          <a data-fancybox="galleryLive" href="assets/images/payment/live/live4.png" class="d-none"></a>
        </div>
      </div>
      <div class="col-md-6">
        <h2 style="text-transform: none;"  class="steps__title mb-3 mt-md-0">
          {{ "gamecloudOne.liveCasinoTitle" | translate }}</h2>
        <h3 class="steps__title-marked mb-3">
          {{ "gamecloudOne.liveCasinoSubTitle" | translate }}
          <br class="d-md-none d-lg-block" />
          {{ "gamecloudOne.liveCasinoSubTitleTwo" | translate }}
        </h3>

        <p class="mb-3">
          {{ "gamecloudOne.liveCasinoText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.liveCasinoTextTwo" | translate }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 order-2 order-md-1">
        <h2 style="text-transform: none;" class="steps__title mb-3 mt-md-0">{{ "gamecloudOne.betTitle" | translate }} ​</h2>

        <h3 class="steps__title-marked mb-3">{{
          "gamecloudOne.betSubTitle" | translate
        }}</h3>

        <p class="mb-3">
          {{ "gamecloudOne.betText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.betTextTwo" | translate }}
        </p>

        <ul>
          <Li>{{ "gamecloudOne.betList" | translate }}​</Li>
          <Li>{{ "gamecloudOne.betListTwo" | translate }}​</Li>
          <Li>{{ "gamecloudOne.betListThree" | translate }}</Li>
        </ul>

        <div class="steps__buttons mb-3 mb-md-0">
          <a (click)="scrollTop()" class="btn main anchor">
            <span><img src="assets/images/payment/crown.svg" alt="Crown" /></span> {{ "gamecloudOne.btnStar" | translate }}
          </a>
          <a (click)="openDialogQuestions()" class="btn ml-md-4 faq-bt" target="_blank">
            {{ "gamecloudOne.btnFrequent" | translate }}
          </a>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="line d-md-none"></div>
        <div class="poster text-right">
          <img src="assets/images/payment/bet-anything.png" alt="bet-anything" />
          <a data-fancybox="galleryBets" href="assets/images/payment/bets/bet1.png" class="link">
            {{ "gamecloudOne.takeLook" | translate }}
          </a>
          <a data-fancybox="galleryBets" href="assets/images/payment/bets/bet2.png" class="d-none"></a>
        </div>
      </div>
    </div>

    <div class="steps__arrow">
      <img src="assets/images/payment/arrow.svg" alt="Arrow" />
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="line d-md-none"></div>
        <div class="poster">
          <img src="assets/images/payment/unique-poker.png" alt="unique-poker" />
          <a data-fancybox="galleryPoker" href="assets/images/payment/poker/poker1.png" class="link">
            Take a closer look
          </a>
          <a data-fancybox="galleryPoker" href="assets/images/payment/poker/poker2.png" class="d-none"></a>
          <a data-fancybox="galleryPoker" href="assets/images/payment/poker/poker3.png" class="d-none"></a>
        </div>
      </div>
      <div class="col-md-6">
        <h2 style="text-transform: none;"  class="steps__title mb-3 mt-md-0">{{
          "gamecloudOne.multiPlayePokerTitle" | translate
        }}</h2>
        <h3 class="steps__title-marked mb-3">{{ "gamecloudOne.multiPlayePokerSubTitle" | translate }} ​</h3>

        <p class="mb-3">
          {{ "gamecloudOne.multiPlayePokerText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.multiPlayePokerTextTwo" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.multiPlayePokerTextThree" | translate }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 order-2 order-md-1">
        <h2 style="text-transform: none;" class="steps__title mb-3 mt-md-0">
          {{ "gamecloudOne.realisticVirtualTitle" | translate }}</h2>
        <h3 class="steps__title-marked mb-3">{{
          "gamecloudOne.realisticVirtualSubTitle" | translate
        }}</h3>

        <p class="mb-3">
          {{ "gamecloudOne.realisticVirtualText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.realisticVirtualTextTwo" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.realisticVirtualTextThree" | translate }}
        </p>

        <div class="steps__buttons">
          <a (click)="scrollTop()" class="btn main anchor">
            <span><img src="assets/images/payment/crown.svg" alt="Crown" /></span> {{ "gamecloudOne.btnStar" | translate }}
          </a>
          <a href="https://cryptobet.com/" class="btn" target="_blank">
            {{ "gamecloudOne.btnDemo" | translate }}
          </a>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="line d-md-none"></div>
        <div class="poster text-right">
          <img src="assets/images/payment/realistic-sports.png" alt="realistic-sports" />
          <a data-fancybox="gallerySports" href="assets/images/payment/sports/sport1.png" class="link">
            {{ "gamecloudOne.takeLook" | translate }}
          </a>
          <a data-fancybox="gallerySports" href="assets/images/payment/sports/sport2.png" class="d-none"></a>
          <a data-fancybox="gallerySports" href="assets/images/payment/sports/sport3.png" class="d-none"></a>
          <a data-fancybox="gallerySports" href="assets/images/payment/sports/sport4.png" class="d-none"></a>
          <a data-fancybox="gallerySports" href="assets/images/payment/sports/sport5.png" class="d-none"></a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="promo">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-9 px-lg-4">
        <img src="assets/images/payment/lapto-cry.png" alt="Laptop" />
        <h2>{{ "gamecloudOne.whatAreTitle" | translate }}</h2>
        <p class="">
          {{ "gamecloudOne.whatAreText" | translate }}
        </p>

        <a (click)="scrollTop()" class="btn anchor">
          <span><img src="assets/images/payment/crown.svg" alt="Crown" /></span> {{ "gamecloudOne.btnGet" | translate }}
        </a>
      </div>
    </div>
  </div>
</section>

<section class="steps">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 order-2 order-md-1">
        <h2 style="text-transform: none;"  class="steps__title mb-3">
          {{ "gamecloudOne.professionalBackTitle" | translate }}</h2>
        <h3 class="steps__title-marked mb-3">
          {{ "gamecloudOne.professionalBackSubTitle" | translate }}
          <br class="d-md-none" />
          {{ "gamecloudOne.professionalBackSubTitleTwo" | translate }}
        </h3>

        <p class="mb-3">
          {{ "gamecloudOne.professionalBackText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.professionalBackTextTwo" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.professionalBackTextThree" | translate }}
        </p>
        <div class="steps__buttons">
          <a routerLink="/usermanuals" class="btn main anchor">
            {{ "gamecloudOne.manuals" | translate }}
          </a>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="poster text-right">
          <img src="assets/images/payment/point-dashboard.png" alt="point-dashboard" />
          <a data-fancybox="galleryDashboard" href="assets/images/payment/dashboard/dashboard1.png" class="link">
            {{ "gamecloudOne.takeLook" | translate }}
          </a>
          <a data-fancybox="galleryDashboard" href="assets/images/payment/dashboard/dashboard2.png" class="d-none"></a>
          <a data-fancybox="galleryDashboard" href="assets/images/payment/dashboard/dashboard3.png" class="d-none"></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="line d-md-none"></div>
        <div class="poster">
          <img src="assets/images/payment/affiliate-system.png" alt="affiliate-system" />
        </div>
      </div>
      <div class="col-md-6">
        <h2 style="text-transform: none;" class="steps__title mb-3">
          {{ "gamecloudOne.affiliateSystemTitle" | translate }}​</h2>
        <h3 class="steps__title-marked mb-3">{{ "gamecloudOne.affiliateSystemSubTitle" | translate }}
          <br class="d-md-none d-lg-block" />
          {{ "gamecloudOne.affiliateSystemSubTitleTwo" | translate }}
        </h3>

        <p class="mb-3">
          {{ "gamecloudOne.affiliateSystemText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.affiliateSystemTextTwo" | translate }}
        </p>

        <div class="steps__buttons mb-3 md-md-0">
          <a style="
              max-width: 216px;
              min-width: 216px;
              background-color: #5cd27d;
            " class="btn main anchor" href="https://cryptobet.com/referral" target="_blank">
            {{ "gamecloudOne.btnDemo" | translate }}
          </a>
        </div>
      </div>
    </div>

    <div class="steps__arrow">
      <img src="assets/images/payment/arrow.svg" alt="Arrow" />
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="line d-md-none"></div>
        <div class="poster">
          <img src="assets/images/payment/crypto-wallet.png" alt="crypto-wallet" />
          <a data-fancybox="galleryPayment" href="assets/images/payment/payment/payment1.png" class="link">
            {{ "gamecloudOne.takeLook" | translate }}
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <h2 style="text-transform: none;"  class="steps__title mb-3">
          {{ "gamecloudOne.integratedPaymentTitle" | translate }}
        </h2>
        <h3 class="steps__title-marked mb-3">{{ "gamecloudOne.integratedPaymentSubTitle" | translate }}​</h3>

        <p class="mb-3">
          {{ "gamecloudOne.integratedPaymentText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.integratedPaymentTextTwo" | translate }}
        </p>
      </div>
    </div>

    <!-- <div class="row">
				<div class="col-md-6 order-2 order-md-1">
					<span class="steps__title">Instant Automated Payouts</span>
					<span class="steps__title-marked">Players Can Cash Out Anytime They Want​​</span>

					<p>With on-demand withdrawals, your players feel safer knowing they're in control of their own money. </p>

					<p>For your (and your player's) security, you can set automated withdrawal limits so that any payout
						requests over a certain amount will require manual approval before being processed.</p>

					<p>Automated payouts are seamlessly handled wallet-to-wallet (currently through a variety of
						cryptocurrencies) with no interaction needed on your end. With proper risk management, you
						have zero worries (we have first-hand experience from running our own consumer-facing brand)
						about any liabilities with automated withdrawals.</p>

					<div class="steps__buttons">
						<a href="#form" class="btn main anchor">
							<span><img src="./images/crown.svg" alt="Crown"></span>	Start Your Casino Now
						</a>
					</div>
				</div>
				<div class="col-md-6 order-1 order-md-2">
					<div class="line d-md-none"></div>
					<div class="poster text-right">
						<img src="./images/instant-payouts.png" alt="instant-payouts">
					</div>
				</div>
			</div>

			<div class="steps__arrow">
				<img src="./images/arrow.svg" alt="Arrow">
			</div> -->

    <div class="row">
      <div class="col-md-6 order-2 order-md-1">
        <h2 style="text-transform: none;"  class="steps__title mb-3">{{ "gamecloudOne.emailMarketingTitle" | translate }}​</h2>
        <h3 class="steps__title-marked mb-3">{{ "gamecloudOne.emailMarketingSubTitle" | translate }}
          <br class="d-md-none d-lg-block" />
          {{ "gamecloudOne.emailMarketingSubTitleTwo" | translate }}​</h3>

        <p class="mb-3">
          {{ "gamecloudOne.emailMarketingText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.emailMarketingTextTwo" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.emailMarketingTextThree" | translate }}
        </p>

        <div class="steps__buttons">
          <a (click)="scrollTop()" class="btn main anchor">
            <span><img src="assets/images/payment/crown.svg" alt="Crown" /></span> {{ "gamecloudOne.btnStar" | translate }}
          </a>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="line d-md-none"></div>
        <div class="poster text-right">
          <img src="assets/images/payment/email-integration.png" alt="point-dashboard" />
          <a data-fancybox="galleryEmail" href="assets/images/payment/email/email1.png" class="link">
            {{ "gamecloudOne.takeLook" | translate }}
          </a>
          <a data-fancybox="galleryEmail" href="assets/images/payment/email/email2.png" class="d-none"></a>
          <a data-fancybox="galleryEmail" href="assets/images/payment/email/email3.png" class="d-none"></a>
          <a data-fancybox="galleryEmail" href="assets/images/payment/email/email4.png" class="d-none"></a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="providers">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-9">
        <h2>
          {{ "gamecloudOne.licensedRegulatedTitle" | translate }}
          <b>{{ "gamecloudOne.licensedRegulatedTitleTwo" | translate }}</b>{{ "gamecloudOne.licensedRegulatedTitleThree" | translate }}
          <b>{{ "gamecloudOne.licensedRegulatedTitleFour" | translate }}</b>
          <br class="d-md-none" /> {{ "gamecloudOne.licensedRegulatedTitleFive" | translate }}
        </h2>
        <p class="text-left">
          {{ "gamecloudOne.licensedRegulatedText" | translate }}
        </p>
      </div>
    </div>

    <div class="providers__list">
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/NET-ENT.png" alt="NET-ENT" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Isoft-Bet.png" alt="Isoft-Bet" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Endorphina.png" alt="Endorphina" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Golden-Race.png" alt="Golden-Race" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/iron.png" alt="iron" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Next-Gen.png" alt="Next-Gen" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Gamevy.png" alt="Gamevy" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Slot-Vision.png" alt="Slot-Vision" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Exp-Gaming.png" alt="Exp-Gaming" />
      </div>

      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Evolution-Gaming.png" alt="Evolution-Gaming" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Ainsworth.png" alt="Ainsworth" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Bet-soft.png" alt="Bet-soft" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Pariplay.png" alt="Pariplay" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Betixon.png" alt="Betixon" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Ezugi.png" alt="Ezugi" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/AG-Asia.png" alt="AG-Asia" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/JFTW.png" alt="JFTW" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Wazdan.png" alt="Wazdan" />
      </div>

      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Microgaming.png" alt="Microgaming" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Digita-in.png" alt="Digita-in" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Fugaso.png" alt="Fugaso" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Vivo-Gaming.png" alt="Vivo-Gaming" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Leap.png" alt="Leap" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/1x2.png" alt="1x2" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Btg.png" alt="Btg" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/2by2.png" alt="2by2" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/One-Touch.png" alt="One-Touch" />
      </div>

      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Play-n-Go.png" alt="Play-n-Go" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Pragmatic.png" alt="Pragmatic" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Habanero.png" alt="Habanero" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/ELK.png" alt="ELK" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Evo-Play.png" alt="Evo-Play" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Leap.png" alt="Leap" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Playson.png" alt="Playson" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Game-art.png" alt="Game-art" />
      </div>
      <div class="providers__list--item">
        <img src="assets/images/payment/providers/Ganapati.png" alt="Ganapati" />
      </div>
    </div>
  </div>
</section>

<section id="setup-fee" name="setup-fee" class="offer-cta steps">
  <div class="container">
    <div class="row justify-content-center mb-md-4">
      <div class="col-12 col-lg-8">
        <h2 class="title mb-3">
          {{ "gamecloudOne.setupFeeTitle" | translate }}
        </h2>
        <p class="p-one pp px-lg-4 text-left">
          {{ "gamecloudOne.setupFeeText" | translate }}
        </p>
      </div>
    </div>

    <div class="row justify-content-center px-lg-4 mb-5">
      <div class="col-12 col-lg-5">
        <div class="card-cta mb-4 mb-md-0">
          <h3 class="card-title mb-4">
            {{ "gamecloudOne.setupFeeOptionOne" | translate }} <br /> {{ "gamecloudOne.setupFeeOptionOneText" | translate }}
          </h3>

          <div class="card-cta__body">
            <div class="card-cta__item">
              <div class="one">
                <img src="assets/images/leviathan-logo.png" alt="" />
              </div>
              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>{{ "gamecloudOne.setupFeeOptionOneList" | translate }}</p>
              </div>
            </div>

            <div class="card-cta__item">
              <div class="one">
                <img style="max-width: 90px" src="assets/images/ezugi-cta.png" alt="" />
              </div>
              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>
                  {{ "gamecloudOne.setupFeeOptionOneListTwo" | translate }}
                </p>
              </div>
            </div>

            <div class="card-cta__item">
              <div class="one">
                <img style="max-width: 71px" src="assets/images/vivo-cta.png" alt="" />
              </div>
              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>
                  {{ "gamecloudOne.setupFeeOptionOneListThree" | translate }}
                </p>
              </div>
            </div>

            <div class="card-cta__item">
              <div class="one">
                <img src="assets/images/rgt-cta.png" alt="" />
              </div>

              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>
                  {{ "gamecloudOne.setupFeeOptionOneListFour" | translate }}
                </p>
              </div>
            </div>

            <div class="card-cta__item mb-0">
              <div class="one">
                <img style="max-width: 56px" src="assets/images/inbet-cta.png" alt="" />
              </div>

              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>
                  {{ "gamecloudOne.setupFeeOptionOneListFive" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="card-cta">
          <h3 class="card-title mb-4">
            {{ "gamecloudOne.setupFeeOptionTwo" | translate }} <br /> {{ "gamecloudOne.setupFeeOptionTwoText" | translate }}
          </h3>

          <div class="card-cta__body">
            <div class="card-cta__item">
              <div class="one">
                <img src="assets/images/logos-cta.png" alt="" />
              </div>

              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>{{ "gamecloudOne.setupFeeOptionTwoList" | translate }}</p>
              </div>
            </div>
            <div class="card-cta__item">
              <div class="one">
                <img src="assets/images/evolution-cta.png" alt="" />
              </div>

              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>{{ "gamecloudOne.setupFeeOptionTwoListTwo" | translate }}</p>
              </div>
            </div>

            <div class="card-cta__item">
              <div class="one">
                <img src="assets/images/Pragmatic.png" alt="" />
              </div>

              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>
                  {{ "gamecloudOne.setupFeeOptionTwoListThree" | translate }}
                </p>
              </div>
            </div>

            <div class="card-cta__item">
              <div class="one">
                <img src="assets/images/rgt-cta.png" alt="" />

                <!--
                <img
                style="max-width: 40px"
                src="assets/images/rgt-prime-logo.png"
                alt=""
              /> -->
              </div>

              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>
                  {{ "gamecloudOne.setupFeeOptionTwoListFour" | translate }}
                </p>
              </div>
            </div>

            <div class="card-cta__item mb-0">
              <div class="one">
                <img src="assets/images/golden-cta.png" alt="" />
              </div>

              <div class="two">
                <img src="assets/images/line-cta.png" alt="" />
              </div>
              <div class="three">
                <p>
                  {{ "gamecloudOne.setupFeeOptionTwoListFive" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-lg-6">
        <h3 class="sub-title mb-3">
          {{ "gamecloudOne.setupFeeSubTitle" | translate }}
          <br class="d-md-none" /> {{ "gamecloudOne.setupFeeSubTitleTwo" | translate }}

          <br class="d-md-none" /> {{ "gamecloudOne.setupFeeSubTitleThree" | translate }}
        </h3>
        <p class="p-one px-lg-4 text-center text-white mb-4 mb-md-5">
          {{ "gamecloudOne.setupFeeTextTwo" | translate }}
        </p>
        <div class="steps__buttons text-center justify-content-center">
          <a style="
              max-width: 216px;
              min-width: 216px;
              background-color: #5cd27d;
            " class="btn main anchor" href="https://cryptobet.com/" target="_blank">
            {{ "gamecloudOne.btnDemo" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="steps offer-api py-5" style="background-color: #f2f6f9">
  <div class="container">
    <div class="row my-0 justify-content-center">
      <div class="col-12">
        <h2 style="text-transform: none;"  class="steps__title text-center">
          {{ "gamecloudOne.platformAlreadyTitle" | translate }}
          <br class="d-md-none" />
          {{ "gamecloudOne.platformAlreadyTitleTwo" | translate }}
        </h2>
        <h3 class="steps__title-marked text-center mb-0">
          <a style="color: #96359e" routerLink="/gamecloud-api">{{
            "gamecloudOne.platformAlreadyText" | translate
          }}</a>
        </h3>
      </div>
    </div>
  </div>
</section>

<section class="steps">
  <div class="container">
    <h2 class="mb-4">
      {{ "gamecloudOne.availableServicesTitle" | translate }}
    </h2>

    <div class="row">


      <div class="col-md-6">
        <h2 style="text-transform: none;"  class="steps__title mb-3">{{
          "gamecloudOne.web3ServiceTitle" | translate
        }}</h2>
        <h3 class="steps__title-marked mb-3">{{ "gamecloudOne.web3ServiceSubTitle" | translate }}
          <br class="d-md-none d-lg-block" />
          {{ "gamecloudOne.web3ServiceSubTitleTwo" | translate }}</h3>

        <p class="mb-3">
          {{ "gamecloudOne.web3ServiceText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.web3ServiceTextTwo" | translate }}
        </p>
      </div>

      <div class="col-md-6 m-auto">
        <div class="poster">
          <img class="m-auto" src="assets/images/payment/metamask-fox-wordmark-horizontal.svg" alt="license" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 order-1 order-md- m-auto">
        <div class="poster">
          <img class="m-auto" src="assets/images/payment/casinolicense.png" alt="license" />
        </div>
      </div>

      <div class="col-md-6 order-2 order-md-1">
        <h2 style="text-transform: none;"  class="steps__title mb-3">{{
          "gamecloudOne.curacaoGamingTitle" | translate
        }}</h2>
        <h3 class="steps__title-marked mb-3">{{ "gamecloudOne.curacaoGamingSubTitle" | translate }}
          <br class="d-md-none d-lg-block" />
          {{ "gamecloudOne.curacaoGamingSubTitleTwo" | translate }}</h3>

        <p class="mb-3">
          {{ "gamecloudOne.curacaoGamingText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.curacaoGamingTextTwo" | translate }}
        </p>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="line d-md-none"></div>
        <div class="poster">
          <img src="assets/images/payment/casinos.png" alt="casinos" />
        </div>
      </div>
      <div class="col-md-6">
        <h2 style="text-transform: none;"  class="steps__title mb-3">{{
          "gamecloudOne.troubleProblemTitle" | translate
        }}</h2>
        <h3 class="steps__title-marked">{{
          "gamecloudOne.troubleProblemSubTitle" | translate
        }}</h3>

        <p class="mb-3">
          {{ "gamecloudOne.troubleProblemText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.troubleProblemTextTwo" | translate }}
        </p>

        <div class="steps__buttons">
          <a class="btn main anchor" href="https://buycasinos.com">BuyCasinos</a>
        </div>
      </div>
    </div>

    <div class="row" id="support" name="support">
      <div class="col-md-6 order-2 order-md-1">
        <h2 style="text-transform: none;"  class="steps__title mb-3">
          {{ "gamecloudOne.customerSupportTitle" | translate }}
        </h2>
        <h3 class="steps__title-marked mb-3">{{
          "gamecloudOne.customerSupportSubTitle" | translate
        }}</h3>

        <p class="mb-3">
          {{ "gamecloudOne.customerSupportText" | translate }}
        </p>

        <p class="mb-3">
          {{ "gamecloudOne.customerSupportTextTwo" | translate }}
        </p>

        <p class="mb-0">
          {{ "gamecloudOne.customerSupportTextThree" | translate }}
        </p>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <div class="line d-md-none"></div>
        <div class="poster text-right">
          <img src="assets/images/payment/live-chat.png" alt="live-chat" />
        </div>
      </div>
    </div>
  </div>
</section>

<section id="trusted-partners" name="trusted-partners" [attr.id]="'trusted-partners'">
  <div class="container">
    <div class="row mt-5 mb-3">
      <div class="line d-md-none"></div>
      <div class="col-12 col-md-6 col-lg-4 px-5 px-md-0 mb-3">
        <img src="assets/images/lapto-home.png" alt="" />
      </div>
      <div class="col-12 col-md-6 col-lg-8 pl-md-4 pl-lg-5 pr-lg-0">
        <h2 style="text-transform: none;"  class="title-marked mb-3">
          {{ "home.allProductTitle" | translate }}
        </h2>
        <p>
          {{ "home.allProductText" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="betting">
  <div class="container">
    <div class="row align-items-md-center">
      <div class="col-12 pb-3 pb-md-0 col-md-6 col-lg-7 pr-lg-1">
        <div class="row">
          <div class="col-12">
            <h2 class="pb-3">{{ "home.bettingTitle" | translate }}</h2>

            <div class="col-12 px-2">
              <p class="mb-4">
                {{ "home.bettingText" | translate }}
              </p>
              <p>
                {{ "home.bettingTextTwo" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-5 left-p-g">
        <div class="row">
          <div class="col-12 mb-2">
            <a routerLink="/gamecloud-page">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-1 pb-2 pb-lg-3 img-game"
                      src="assets/images/gamecloud-home.png"
                      alt=""
                    />
                    <p>{{ "home.bettingGamecloud" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img
                      class="d-md-none"
                      src="assets/images/gamecloud-web.png"
                      alt=""
                    />
                    <img
                      class="d-none d-md-block"
                      src="assets/images/gamecloud-web-des.png"
                      alt=""
                    />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-white.svg"
                      alt=""
                    />
                  </div>
                </div></div
            ></a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/casino-hosting">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pb-2 img-ch"
                      src="assets/images/casino-hosting-home.png"
                      alt=""
                    />
                    <p>{{ "home.bettingCasinoHosting" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/casino-hosting-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-white.svg"
                      alt=""
                    />
                  </div>
                </div></div
            ></a>
          </div>

       <!--    <div class="col-12 mb-2">
            <a routerLink="/cryptobet">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-1 pt-md-2 pb-2 img-cb"
                      src="assets/images/cryptobet-home.png"
                      alt=""
                    />
                    <p>{{ "home.bettingCryptobet" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img
                      class=""
                      src="assets/images/cryptobet-web.png"
                      alt=""
                    />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-white.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div> -->

          <div class="col-12 mb-2">
            <a routerLink="/casino-license">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pt-md-2 pb-2 pb-lg-3 img-cl"
                      src="assets/images/casino-license-home.png"
                      alt=""
                    />
                    <p>{{ "home.bettingCasinoLicense" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/casinol-icense-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-white.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/leviathan">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pb-2 pb-lg-2 img-l"
                      src="assets/images/leviathan-logo.png"
                      alt=""
                    />
                    <p>{{ "home.bettingLeviathan" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/leviathan-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-white.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/buycasinos">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pb-2 pb-lg-3 img-l"
                      src="assets/images/buyscasinos-logo.png"
                      alt=""
                    />
                    <p>{{ "home.bettingBuyCasinos" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/buycasinos-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-white.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="defi">
  <div class="container">
    <div class="row align-items-md-center">
      <div class="col-12 pb-3 col-md-6 col-lg-7 pb-md-0 order-md-1">
        <div class="row">
          <div class="col-12">
            <h2 class="pb-3">{{ "home.fintechTitle" | translate }}</h2>

            <div class="col-12 px-2">
              <p class="mb-3">
                {{ "home.fintechText" | translate }}
              </p>
              <p class="mb-3">
                {{ "home.fintechTextTwo" | translate }}
              </p>
              <p>
                {{ "home.fintechTextThree" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-5 left-p-g order-md-0">
        <div class="row">
          <div class="col-12 mb-2">
            <a routerLink="/tpay">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-tpay"
                      src="assets/images/tpay-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechTpay" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/tpay-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img src="assets/images/arrow-left-purple.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/efin">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-efin-logo"
                      src="assets/images/efin-home.png"
                      alt=""
                    />
                    <p>
                      {{ "home.fintechEfin" | translate }}
                    </p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/efin-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/cointeller">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-ct"
                      src="assets/images/cointeller-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechCointeller" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/cointeller-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/infinitesimal">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-infi"
                      src="assets/images/infinitesimal-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechInfinitesimal" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/infinitesimal-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/rgt-prime">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-rgt-prime"
                      src="assets/images/rgt-premium-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechRGTPrime" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/blast-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/blocksize">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-rgt-prime"
                      src="assets/images/blocksize-logo-web.png"
                      alt=""
                    />
                    <p>{{ "home.fintechBlocksize" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/data-security-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="promo steps">
  <div class="container">
    <h2 class="regular">
      {{ "gamecloudOne.tomorrowReallyTitle" | translate }}​
    </h2>

    <div class="row justify-content-center mb-2">
      <div class="col-12 d-none d-md-block">
        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyText" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextTwo" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextThree" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextFour" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextFive" | translate }}
        </p>
      </div>
      <div class="col-12 d-md-none">
        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyText" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextTwo" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextThree" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextFour" | translate }}
        </p>

        <p class="text-white">
          {{ "gamecloudOne.tomorrowReallyTextFive" | translate }}
        </p>
      </div>
    </div>

    <div class="steps__buttons justify-content-center">
      <a (click)="scrollTop()" class="btn main anchor">
        <span><img src="assets/images/payment/crown.svg" alt="Crown" /></span> {{ "gamecloudOne.btnStar" | translate }}
      </a>
      <a style="background-color: #5cd27d" (click)="openDialogQuestions()" class="btn ml-md-4 faq-bt" target="_blank">
        {{ "gamecloudOne.btnFrequent" | translate }}
      </a>
    </div>
  </div>
</section>
