import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-casino-license",
  templateUrl: "./casino-license.component.html",
  styleUrls: ["./casino-license.component.scss"],
})
export class CasinoLicenseComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    let header = document.getElementById("header");
    header.classList.remove("--not-found");
    header.style.display = "flex";
  }
}
