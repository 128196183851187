import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-thank-you-page",
  templateUrl: "./thank-you-page.component.html",
  styleUrls: ["./thank-you-page.component.scss"],
})
export class ThankYouPageComponent implements OnInit {


  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    let none = document.getElementById("thank-you");
    if (none) {
      let header = document.getElementById("header");
      header.classList.add("--not-found");
    }
  }





}
