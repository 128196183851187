<section class="section-pages">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 col-lg-5 mb-4 mb-md-5">
        <img
          class="px-5 px-md-0"
          src="assets/images/cryptobet-page.png"
          alt=""
        />
      </div>

      <div class="col-12 col-md-6 col-lg-7 pl-md-4 pl-lg-5">
        <div class="mb-3 pages-logo">
          <img src="assets/images/cryptobet-logo.svg" alt="" />
        </div>

        <p class="mb-0 mb-md-4">
          {{ "cryptoBet.text" | translate }}

          <a
            href="https://medium.com/rgtglobal/cryptobet-cbet-token-economics-utility-governance-liquidity-plan-a8d0d1435602"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ "cryptoBet.link" | translate }}
          </a>
        </p>
        <div class="btn-container">
          <a
            class="btn mb-0 mb-md-5"
            href="https://cryptobet.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ "cryptoBet.btn" | translate }}

            <span>
              {{ "cryptoBet.btnTwo" | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="row pb-5">
      <div class="col-12">
        <p class="pb-4">
          {{ "cryptoBet.textTwo" | translate }}
        </p>

        <p>
          {{ "cryptoBet.textThree" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
