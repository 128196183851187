import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
@Component({
  selector: 'app-coinpayment-pdf',
  templateUrl: './coinpayment-pdf.component.html',
  styleUrls: ['./coinpayment-pdf.component.scss']
})
export class CoinpaymentPdfComponent implements OnInit {
  pdf = "docs/coinpayment-doc-en.pdf";
  constructor(public dialogRef: MatDialogRef<CoinpaymentPdfComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
