import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public selectedLanguage = 'en';

  constructor() { }

}
