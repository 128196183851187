import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-terms-andconditions",
  templateUrl: "./terms-andconditions.component.html",
  styleUrls: ["./terms-andconditions.component.scss"],
})
export class TermsAndconditionsComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TermsAndconditionsComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  ngOnInit() {}
}
