import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AffiliatesService } from 'src/app/services/affiliates.service';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss'],
})

export class AffiliatesComponent implements OnInit {
  d = document;

  isSignup = false;
  authForm: FormGroup;
  registerForm: FormGroup;

  loginError = '';
  registerError = '';

  registerSuccess = '';

  constructor(public router: Router, public affiliatesService: AffiliatesService) { }

  ngOnInit() {
    let header = document.getElementById('header');
    header.classList.remove('--not-found');
    header.style.display = 'flex';

    this.authForm = new FormGroup({
      userid: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });

    this.registerForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required,]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });

  }

  onSignup(event) {
    console.log(event);
  }

  register() {
    this.registerError = 'Creating your account, Please wait...';

    this.affiliatesService.registerAffiliates(this.registerForm.value).then((response) => {
      const data = response;

      if (data['created'] === false) {
        this.registerError = 'An error ocurred creating your account, please check your form and try again';
      } else {
        this.registerSuccess = 'Your account was created successfully.<br> Affiliate Accounts must be approved by Administrators. <br> Details will be sent to the provided email address ' + data['email'];
      }

    }).catch((error: any) => {
      this.registerError = 'An error ocurred creating your account, Please try again later';

    });

  }

  login() {
    let form = <HTMLFormElement>document.getElementById("loginForm");

    this.affiliatesService.loginAffiliates(this.authForm.value).then((response) => {
      const data = response;

      if (data['bool'] === true) {
        form.submit();

      } else {
        this.loginError = data['message'];

      }

    });

  }

  scrollTop() {
    this.d.body.scrollTop = 0;
    this.d.documentElement.scrollTop = 0;
  }
}
