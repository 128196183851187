<h2 class="texts__title --manual tab-title mb-3">Create Players</h2>
<p class="texts__paragraphs --manual tab-p mb-3">
  To create a new Agent go to Dashboard > Create Users > Agent. You have to be logged from your Agent Account.
</p>

<p class="texts__paragraphs --manual tab-p mb-4 mb-lg-5">
  Fill the form, select all the providers to use and Click on “Create User” button.
</p>

<img class="mb-4 mb-lg-5" src="assets/images/handbook/create-users/agent.png" alt="create agent" />
