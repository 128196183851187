import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PaymentsService } from "src/app/services/payments.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  form: FormGroup;

  d = document;

  sended=false;
  constructor(public router: Router, private formBuilder: FormBuilder, private paymentsService: PaymentsService, public translate: TranslateService) {

    this.buildForm();
  }

  ngOnInit() {

    let contact = document.getElementById("contact-header");
    if (contact) {

      let header = document.getElementById("header-link");
      header.classList.add("--not-found");
    }
  }

  scrollTop() {
    this.router.navigate(["/gamecloud"]);
    this.d.body.scrollTop = 0;
    this.d.documentElement.scrollTop = 0;
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      fullName: [
        "",
        [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(/^[a-zA-Z_-\s]*$/),
        ],
      ],
      email: ["", [Validators.required, Validators.email]],

      phone: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/),
        ],
      ],
      message: ["", [Validators.required]],
    });

    this.form.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  onFormSubmit(e) {
    console.log(e);
    // e.target.textContent = this.translate.getTranslation("form.btnSubmit");
    if (this.form.invalid === true) {
      this.form.markAllAsTouched();
      // e.target.textContent = this.translate.getTranslation("form.btnSubmit");
      return;
    } else {
      // e.target.textContent = this.translate.getTranslation("form.btnSubmit");
      this.paymentsService.SendContactForm(this.form.value);
      this.sended = true;
      this.form.reset();

      setTimeout(() => {
        this.sended = false;
      }, 5000);
    }
  }
}
