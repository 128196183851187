<section class="section text-legal">

  <div class="container">

    <div class="row mb-4 mb-lg-5">

      <div class="col-12">

        <h2 class="texts__title --about mb-4 mb-lg-3">
          REFUND & CANCELLATION POLICY
        </h2>

        <p class="texts__paragraphs">
          Our refund policy has been devised to define the situations under which Royal Gaming Technology (“RGT”) will provide a refund, the procedure for claiming a refund and the responsibility of RGT in circumstances resulting in such a claim. By registering
          for any of our services you are declaring that you accept and agree with all the terms and conditions outlined in the refund policy. Please read and make sure you fully understand our refund policy prior to making a payment.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Coverage & Scope
        </h2>

        <p class="texts__paragraphs">
          Our refund policy covers the refunds by RGT and/or a website owned and managed by RGT. This policy does not have any application for companies which are not owned or controlled by RGT or for persons not employed or managed by RGT. This includes any third
          party service and / or product providers bound by contract and also, any third-party websites to which RGT’s website link.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Filing a Complaint
        </h2>

        <p class="texts__paragraphs">
          At RGT, we believe that every effort should be made to reach a solution that is fully acceptable reciprocally in case of any situation where dissatisfaction related to services comes. Only when things are completely out of hand should a refund be considered.
          As per our web and mobile development terms and conditions, if you cancel a contracted project at any point after the work commences, you will be liable for all work charges incurred up to the cancellation point. Once you agree for a site or
          app to go live on the internet, then you are agreeing that you are fully satisfied with the work and no future refunds can be given for the fees paid.
        </p>

        <p class="texts__paragraphs">
          To file a complaint, send your request to support@rgt.com.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Chargebacks
        </h2>

        <p class="texts__paragraphs">
          If any chargeback or payment dispute is caused by client to a bank or credit card company, then client services and/or projects will be suspended, and in some cases all work will be undone (reversed) without notice. Furthermore, the amount of the chargeback
          fee will be issued to client (to recover any fees passed on to us by the credit card company).
        </p>

        <p class="texts__paragraphs">
          In addition, any outstanding balances accrued as a result of the chargeback(s) will have to be paid in full before we restore service, deliver any files, or commence any work.
        </p>

        <p class="texts__paragraphs">
          Requesting a chargeback or opening a dispute for a valid charge is fraud. Chargebacks are never a legal or appropriate means of obtaining a refund. All disputes will be challenged by our lawyers with sufficient evidence from our records.
        </p>

        <h2 class="texts__title --about mb-4 mb-lg-3">
          Account Suspension
        </h2>

        <p class="texts__paragraphs">
          All payments that are 15 days past due will trigger an account suspension.
        </p>

        <p class="texts__paragraphs">
          RGT reserves the right to disable and/or terminate a user’s account, services, or contract if a user is found in violation of our terms and policies. Termination due to policy violations will not be refunded.
        </p>

        <p class="texts__paragraphs">
          Please also read our <a (click)="scrollTop()" routerLink="/terms-and-conditions">TERMS AND CONDITIONS.</a>
        </p>

      </div>

    </div>

  </div>
</section>