import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { HomeComponent } from "./components/pages/home/home.component";
import { ApiDirectComponent } from "./components/pages/api-direct/api-direct.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { AffiliatesComponent } from "./components/pages/affiliates/affiliates.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { ThankYouPageComponent } from "./components/pages/thank-you-page/thank-you-page.component";
import { RefundPolicyComponent } from "./components/pages/refund-policy/refund-policy.component";
import { PaymentComponent } from "./components/pages/payment/payment.component";
import { OurCustomersComponent } from "./components/pages/our-customers/our-customers.component";
import { BlastComponent } from "./components/pages/blast/blast.component";
import { LeviathanComponent } from "./components/pages/leviathan/leviathan.component";
import { CasinoLicenseComponent } from "./components/pages/casino-license/casino-license.component";
import { CasinoHostingComponent } from "./components/pages/casino-hosting/casino-hosting.component";
import { CointellerComponent } from "./components/pages/cointeller/cointeller.component";
import { CryptobetComponent } from "./components/pages/cryptobet/cryptobet.component";
import { GamecloudPageComponent } from "./components/pages/gamecloud-page/gamecloud-page.component";
import { InfinitesimalComponent } from "./components/pages/infinitesimal/infinitesimal.component";
import { EfinComponent } from "./components/pages/efin/efin.component";
import { TpayComponent } from "./components/pages/tpay/tpay.component";
import { BlocksizeComponent } from "./components/pages/blocksize/blocksize.component";
import { BuycasinosComponent } from "./components/pages/buycasinos/buycasinos.component";
import { CasinoSetupComponent } from "./components/pages/casino-setup/casino-setup.component";
import { HandbookComponent } from "./components/pages/handbook/handbook.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  /* { path: "", redirectTo: "/gamecloud", pathMatch: "full" }, */
  { path: "", component: PaymentComponent },
  { path: "gamecloud-api", component: ApiDirectComponent },
/*   { path: "about", component: AboutComponent }, */
  { path: "affiliates", component: AffiliatesComponent },
  { path: "contact", component: ContactComponent },
  { path: "refund-policy", component: RefundPolicyComponent },
  { path: "gamecloud", component: PaymentComponent },
  { path: "thank-you", component: ThankYouPageComponent },
  { path: "our-customers", component: OurCustomersComponent },
  { path: "trusted-partners", component: PaymentComponent, data: { section: "partners" } },
  { path: "rgt-prime", component: BlastComponent },
  { path: "casino-license", component: CasinoLicenseComponent },
  { path: "casino-hosting", component: CasinoHostingComponent },
  { path: "cointeller", component: CointellerComponent },
  { path: "cryptobet", component: CryptobetComponent },
  { path: "gamecloud-page", component: GamecloudPageComponent },
  { path: "infinitesimal", component: InfinitesimalComponent },
  { path: "leviathan", component: LeviathanComponent },
  { path: "blocksize", component: BlocksizeComponent },
  { path: "buycasinos", component: BuycasinosComponent },
  { path: "faq", component: PaymentComponent, data: { section: "faq" } },
  { path: "efin", component: EfinComponent },
  { path: "tpay", component: TpayComponent },
  { path: "setup", component: CasinoSetupComponent },
  { path: "usermanuals", component: HandbookComponent },
  { path: "gamecloud/:pageSection", component: PaymentComponent },
  { path: ":language/gamecloud/:pageSection", component: PaymentComponent },
  { path: ":language/gamecloud", component: PaymentComponent },
  { path: ":language", component: PaymentComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
