import { Component, OnInit, ɵConsole, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import * as $ from "jquery";

import sha256 from "crypto-js/sha256";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PaymentsService } from "src/app/services/payments.service";
import { TermsAndconditionsComponent } from "../../modal/terms-andconditions/terms-andconditions.component";
import { FrequentQuestionsComponent } from "../../modal/frequent-questions/frequent-questions.component";
import { isNullOrUndefined } from "util";
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  d = document;
  allowSubmit = false;

  formPayment: FormGroup;

  checkboxEnabled: boolean = false;

  coinpayments: FormGroup;

  /* For Coin Payments */
  orderNo: string;
  cmd = "_pay_simple";
  reset = "1";
  merchant = "8aab2fdc271517dea16de9f2047730d0";
  item_name = "Online Casino";
  currency = "USD";
  amountf = "4995.00";
  want_shipping = "0";

  actionURLCP = "https://www.coinpayments.net/index.php";
  sucessURL = "https://rgt.com/thank-you";
  cancelURL = "https://www.rgt.com/gamecloud";
  ipnURL = this.paymentsService.api + "/cp_ipn";

  affiliate_id = '';

  pageSection = '';

  constructor(
    private paymentsService: PaymentsService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    private languageService: LanguageService,
    public translateService: TranslateService,
  ) {
    this.buildFormPayment();
    this.buildFormCoinpayments();
  }

  ngOnInit() {
    try {
      this.route.queryParams.subscribe(params => {
        this.affiliate_id = params['idev_id'];
        console.log('Affiliate ID: ' + this.affiliate_id);
      });

    } catch (error) {

    }

    let offer = document.getElementById("payment__page");

    if (offer) {
      let header = document.getElementById("logop");
    }

    let id = "";

    this.route.data.subscribe((data) => {
      id = data.section;
    });

    if (id === "faq") {
      this.scrollTop();
      this.openDialogQuestions();
    }

    if (id === "partners") {
      this.router.navigate( ['/'], {fragment: 'trusted-partners'});
    }

    this.languageService.selectedLanguage = this.route.snapshot.params['language'];
    this.pageSection = this.route.snapshot.params['pageSection'];

    if ((this.languageService.selectedLanguage === 'es') || (this.languageService.selectedLanguage === 'en')) {
      this.translateService.use(this.languageService.selectedLanguage);
    } else {
      this.languageService.selectedLanguage = 'en';
      this.translateService.use('en');
    }

    if (this.pageSection === 'fees'){
      this.router.navigate( ['/gamecloud'], {fragment: 'setup-fee'});
    }

    if (this.pageSection === 'support'){
      this.router.navigate( ['/gamecloud'], {fragment: 'support'});
    }

    if (this.pageSection === 'partners'){
      this.router.navigate( ['/gamecloud'], {fragment: 'partners'});
    }
  }

  openDialogQuestions(): void {
    const dialogRef = this.dialog.open(FrequentQuestionsComponent, {
      panelClass: "my-dialog",
    });
    dialogRef.afterClosed().subscribe((res) => { });
  }

  private buildFormPayment() {
    this.formPayment = this.formBuilder.group({
      names: [
        "",
        [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(/^[a-zA-Z_-\s]*$/),
        ],
      ],
      email: ["", [Validators.required, Validators.email]],
    });

    this.formPayment.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  formValidOne(e) {
    e.preventDefault();
    if (
      this.formPayment.get("names").hasError("required") ||
      this.formPayment.get("names").hasError("maxlength") ||
      this.formPayment.get("names").hasError("pattern") ||
      this.formPayment.get("email").hasError("required") ||
      this.formPayment.get("email").hasError("email")
    ) {
      this.formPayment.get("names").markAllAsTouched();
      this.formPayment.get("email").markAllAsTouched();
    } else {
      this.processPayment();
    }
  }

  processPayment() {
    this.orderNo = Date.now().toString();
    this.coinpayments.patchValue({
      invoice: this.orderNo,
    });

    let form = <HTMLFormElement>document.getElementById("cpForm");
    console.log(form);

    this.formPayment.value.methodPayments = "coinpayment";
    this.formPayment.value.cmd = this.cmd;
    this.formPayment.value.reset = this.reset;
    this.formPayment.value.merchant = this.merchant;
    this.formPayment.value.item_name = this.item_name;
    this.formPayment.value.currency = this.currency;
    this.formPayment.value.amountf = this.amountf;
    this.formPayment.value.want_shippping = this.want_shipping;
    this.formPayment.value.orderNo = this.orderNo;
    this.formPayment.value.affiliate_id = this.affiliate_id === undefined ? '-1' : this.affiliate_id;

    console.log(this.formPayment.value);
    this.paymentsService.CreatePaymentSimple(this.formPayment.value);
    form.submit();
  }

  scrollTop() {
    this.d.body.scrollTop = 0;
    this.d.documentElement.scrollTop = 0;
  }

  private buildFormCoinpayments() {
    this.coinpayments = new FormGroup({
      cmd: new FormControl(this.cmd, [Validators.required]),
      reset: new FormControl(this.reset, [Validators.required]),
      merchant: new FormControl(this.merchant, [Validators.required]),
      item_name: new FormControl(this.item_name, [Validators.maxLength(200)]),
      invoice: new FormControl("", [Validators.maxLength(200)]),
      currency: new FormControl(this.currency, [Validators.required]),
      amountf: new FormControl(this.amountf, [Validators.required]),
      want_shipping: new FormControl(this.want_shipping, [Validators.required]),
      success_url: new FormControl(this.sucessURL, [Validators.required]),
      cancel_url: new FormControl(this.cancelURL, [Validators.required]),
      inp_url: new FormControl(this.ipnURL, [Validators.required]),
    });

    this.coinpayments.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TermsAndconditionsComponent, {});

    dialogRef.afterClosed().subscribe((res) => {
      if (!isNullOrUndefined(res)) {
        $("#termsCheck").prop("checked", res);
        this.checkboxEnabled = res;
      }
    });
  }

  onCheckboxClick($event) {
    this.checkboxEnabled = !this.checkboxEnabled;
    $("#termsCheck").prop("checked", this.checkboxEnabled);
  }
}
