import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-refund-policy",
  templateUrl: "./refund-policy.component.html",
  styleUrls: ["./refund-policy.component.scss"],
})
export class RefundPolicyComponent implements OnInit {
  d = document;
  constructor() {}

  ngOnInit() {
    let menu = document.querySelector(".header__nav");
    menu.classList.add("bg-primary");
    let header = document.getElementById("header");
    header.classList.remove("--not-found");
    header.style.display = "flex";
  }

  scrollTop() {
    this.d.body.scrollTop = 0;
    this.d.documentElement.scrollTop = 0;
  }
}
