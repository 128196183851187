<header id="casino-setup" class="casino-setup">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-5 px-md-0">
        <h1 class="mb-4">{{ "casinoSetup.title" | translate }}</h1>

        <p class="mb-4">
          {{ "casinoSetup.text" | translate }}

          <br class="d-none d-md-block d-lg-none" /> {{ "casinoSetup.textTwo" | translate }}
        </p>

        <span class="mb-4">
          {{ "casinoSetup.textThree" | translate }}
        </span>

        <form [formGroup]="form" id="setup_form" class="form mt-4" (ngSubmit)="onFormSubmit()" method="POST">
          <label class="mb-3" for="email">Email</label>
          <div class="form-group email mb-4">
            <input formControlName="email" id="email" name="email" type="text" class="form-control" placeholder="{{ 'form.email' | translate }}" />
            <div class="mt-2" *ngIf="form.get('email').errors && form.get('email').touched">
              <p class="texts__error" *ngIf="form.get('email').hasError('required')">
                {{ "form.requiredField" | translate }}
              </p>
              <p class="texts__error mt-2" *ngIf="form.get('email').hasError('email')">
                Invalid Mail
              </p>
            </div>
          </div>

          <label class="mb-3" for="www">{{
            "form.domainName" | translate
          }}</label>
          <div class="form-group www mb-3">
            <input formControlName="domain" id="domain" name="domain" type="url" class="form-control" placeholder=" {{ 'form.exampleDomain' | translate }}" />
            <div class="mt-2" *ngIf="form.get('domain').errors && form.get('domain').touched">
              <p class="texts__error" *ngIf="form.get('domain').hasError('required')">
                {{ "form.requiredField" | translate }}
              </p>
            </div>
          </div>

          <p class="mb-4">
            {{ "form.nameServers" | translate }}
          </p>

          <label class="mb-3" for="www">{{ "form.provideLogo" | translate }}
          </label>
          <div class="d-flex">
            <img style="display: inline-block" class="mb-3" src="{{ imageSrc }}" />
          </div>
          <div *ngIf="imgSize">
            <p class="mb-3" style="color: red">
              The size of the logo is incorrect, it cannot be larger than 231px
            </p>
          </div>
          <div *ngIf="imgType">
            <p class="mb-3" style="color: red">
              Wrong logo format must be png, jpg, jpeg or svg
            </p>
          </div>
          <p class="mb-3">
            {{ "form.logoText" | translate }}
          </p>
          <div class="file-container mb-4">
            <div class="mr-4">
              <input (change)="onFileChange($event)" type="file" id="file" name="file" />
              <label for="file" class="btn-1">{{
                "form.btnLogo" | translate
              }}</label>
              <div class="mt-2" *ngIf="form.get('logo').errors && form.get('logo').touched">
                <p class="texts__error" *ngIf="form.get('logo').hasError('required')">
                  {{ "form.requiredField" | translate }}
                </p>
              </div>
            </div>

            <div class="check-logo">
              <div class="custom-control custom-radio mb-0" style="min-height: 0">
                <input type="checkbox" id="logo" name="logo" formControlName="logo" class="custom-control-input" />
                <label class="custom-control-label" for="logo">{{
                  "form.requiredLogo" | translate
                }}</label>
              </div>
            </div>
          </div>

          <label class="mb-3" for="www">{{
            "form.requiredLicenseGaming" | translate
          }}</label>

          <div class="row no-gutters checkboxes mb-3">
            <div class="col-12">
              <div>
                <label for="yes-license" class="radio mb-2">
                  <input
                    (click)="openCommentLicense($event)"
                    type="radio"
                    value="yes"
                    id="yes-license"
                    formControlName="gamingLicense"
                    class="hidden"
                  />
                  <span class="label"></span>{{ "form.yes" | translate }}
                </label>

                <label for="no-license" class="radio">
                  <input
                    (click)="openCommentLicense($event)"
                    type="radio"
                    value="no"
                    id="no-license"
                    formControlName="gamingLicense"
                    class="hidden"
                  />
                  <span class="label"></span>{{ "form.no" | translate }}
                </label>
                <div class="mt-2" *ngIf="
                    form.get('gamingLicense').errors &&
                    form.get('gamingLicense').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('gamingLicense').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <textarea formControlName="gamingLicenseComments" name="information" id="iComment" cols="30" rows="5" placeholder="{{ 'form.information' | translate }}"></textarea>
          </div>

          <p class="mb-4">
            {{ "form.informationText" | translate }}
          </p>

          <label class="mb-3" for="region">{{
            "form.regionLabel" | translate
          }}</label>

          <div class="form-group mb-3">
            <input formControlName="region" type="text" class="form-control" id="region" name="region" placeholder="{{ 'form.country' | translate }}" />
            <div class="mt-2" *ngIf="form.get('region').errors && form.get('region').touched">
              <p class="texts__error" *ngIf="form.get('region').hasError('required')">
                {{ "form.requiredField" | translate }}
              </p>
            </div>
          </div>

          <p class="mb-4">
            {{ "form.countryText" | translate }}
          </p>

          <label class="mb-3" for="region">
            {{ "form.casinoPackage" | translate }}
          </label>

          <div class="row mb-3 no-gutters checkboxes">
            <div class="col-6" *ngFor="let data of CasinoPackageData; let i = index">
              <div class="custom-control custom-radio">
                <input type="checkbox" id="{{ data.value}}" name="{{ data.value}}" class="custom-control-input" [value]="data.value" (change)="onCheckboxCasinoPackage($event)" />
                <label class="custom-control-label" for="{{ data.value}}">{{ data.name | translate }}</label
                >
              </div>
            </div>
            <div
              class="mt-2"
              *ngIf="
                form.get('casinoPackage').errors &&
                form.get('casinoPackage').touched
              "
            >
              <p
                class="texts__error"
                *ngIf="form.get('casinoPackage').hasError('required')"
              >
                {{ "form.requiredField" | translate }}
              </p>
            </div>
          </div>

          <p class="mb-4">
            {{ "form.casinoPackageText" | translate }}
          </p>

          <label class="mb-3" for="region">{{"form.multiPlayer" | translate}}</label>

                <div class="mb-3 mb-md-4">
                  <label for="yes-multiplayer" class="radio mb-2">
              <input
                type="radio"
                value="yes"
                id="yes-multiplayer"
                formControlName="multiPlayer"
                class="hidden"
              />
              <span class="label"></span>{{ "form.yes" | translate }}
            </label>

                  <label for="no-multiplayer" class="radio">
              <input
                type="radio"
                value="no"
                id="no-multiplayer"
                formControlName="multiPlayer"
                class="hidden"
              />
              <span class="label"></span>{{ "form.no" | translate }}
            </label>

                  <div class="mt-2" *ngIf="
                form.get('multiPlayer').errors &&
                form.get('multiPlayer').touched
              ">
                    <p class="texts__error" *ngIf="form.get('multiPlayer').hasError('required')">
                      {{ "form.requiredField" | translate }}
                    </p>
                  </div>

                </div>
                <p class="mb-4">
                  {{ "form.multiPlayerText" | translate }}
                </p>
                <label class="mb-3" for="region">{{ "form.paymentProcessing" | translate }}</label>
                <div class="row mb-3 no-gutters checkboxes">
                  <div class="col-12" *ngFor="let data of paymentProcessingData; let i = index">
                    <div class="custom-control custom-radio">
                      <input type="checkbox" id="{{ data.value }}" name="{{ data.value }}" class="custom-control-input" [value]="data.value" (change)="onCheckboxPaymentProcessing($event)" />
                      <label class="custom-control-label" for="{{ data.value}}">{{ data.name | translate }}</label
                >
              </div>
            </div>
            <div
              class="mt-2"
              *ngIf="
                form.get('paymentProcessing').errors &&
                form.get('paymentProcessing').touched
              "
            >
              <p
                class="texts__error"
                *ngIf="form.get('paymentProcessing').hasError('required')"
              >
                {{ "form.requiredField" | translate }}
              </p>
            </div>
          </div>

          <p class="mb-4">
            {{ "form.paymentProcessingText" | translate }}
          </p>

          <label class="mb-3" for="region">{{
            "form.customPayment" | translate
          }}</label>

                      <div class="form-group mb-3">
                        <!-- <input type="text" class="form-control" placeholder="Which country will most of your players play from"> -->

                        <div class="mb-3 mb-md-4">
                          <label for="yes-payment" class="radio mb-2">
                <input
                  (click)="openCommentPayment($event)"
                  type="radio"
                  value="yes"
                  id="yes-payment"
                  formControlName="customPayment"
                  class="hidden"
                />
                <span class="label"></span>{{ "form.yes" | translate }}
              </label>

                          <label for="no-payment" class="radio">
                <input
                  (click)="openCommentPayment($event)"
                  type="radio"
                  value="no"
                  id="no-payment"
                  formControlName="customPayment"
                  class="hidden"
                />
                <span class="label"></span>{{ "form.no" | translate }}
              </label>

                          <div class="mt-2" *ngIf="
                  form.get('customPayment').errors &&
                  form.get('customPayment').touched
                ">
                            <p class="texts__error" *ngIf="form.get('customPayment').hasError('required')">
                              {{ "form.requiredField" | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <textarea formControlName="customPaymentComments" name="information" id="iPayment" cols="30" rows="5" placeholder="{{ 'form.comment' | translate }}"></textarea>
                      </div>

                      <p class="mb-4">
                        {{ "form.customPaymentText" | translate }}
                      </p>

                      <label class="mb-3" for="region">{{
            "form.currency" | translate
          }}</label>
                      <div class="form-group mb-3">
                        <input formControlName="currency" type="text" class="form-control" placeholder="{{ 'form.moneda' | translate }}" />
                        <div class="mt-2" *ngIf="
                form.get('currency').errors && form.get('currency').touched
              ">
                          <p class="texts__error" *ngIf="form.get('currency').hasError('required')">
                            {{ "form.requiredField" | translate }}
                          </p>
                        </div>
                      </div>

                      <p class="mb-4">
                        {{ "form.currencyText" | translate }}
                      </p>

                      <label class="mb-3" for="region">{{
            "form.companySetup" | translate
          }}</label>

                      <div class="form-group mb-3">
                        <div class="mb-3 mb-md-4">
                          <label for="yes-septu" class="radio mb-2">
                <input
                  type="radio"
                  value="yes"
                  id="yes-septu"
                  formControlName="companySetup"
                  class="hidden"
                />
                <span class="label"></span>{{ "form.yes" | translate }}
              </label>

                          <label for="no-septu" class="radio">
                <input
                  type="radio"
                  value="no"
                  id="no-septu"
                  formControlName="companySetup"
                  class="hidden"
                />
                <span class="label"></span>{{ "form.no" | translate }}
              </label>

                          <div class="mt-2" *ngIf="
                  form.get('companySetup').errors &&
                  form.get('companySetup').touched
                ">
                            <p class="texts__error" *ngIf="form.get('companySetup').hasError('required')">
                              {{ "form.requiredField" | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <p class="mb-4">
                        {{ "form.companySetupText" | translate }}
                      </p>

                      <label class="mb-3" for="region">
            {{ "form.trained" | translate }}</label
          >

          <div class="form-group mb-3">
            <div class="mb-3 mb-md-4">
              <label for="yes-administrative" class="radio mb-2">
                <input
                  type="radio"
                  value="yes"
                  id="yes-administrative"
                  formControlName="administrativeAssistant"
                  class="hidden"
                />
                <span class="label"></span>{{ "form.yes" | translate }}
              </label>

                      <label for="no-administrative" class="radio">
                <input
                  type="radio"
                  value="no"
                  id="no-administrative"
                  formControlName="administrativeAssistant"
                  class="hidden"
                />
                <span class="label"></span>{{ "form.no" | translate }}
              </label>

                      <div class="mt-2" *ngIf="
                  form.get('administrativeAssistant').errors &&
                  form.get('administrativeAssistant').touched
                ">
                        <p class="texts__error" *ngIf="
                    form.get('administrativeAssistant').hasError('required')
                  ">
                          {{ "form.requiredField" | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <p class="mb-4">
                    {{ "form.trainedText" | translate }}
                  </p>

                  <button type="submit" class="btn">
            {{ "form.btnSubmit" | translate }}
          </button>
        </form>
        </div>
        </div>
        </div>
</header>