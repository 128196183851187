import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-casino-setup',
  templateUrl: './casino-setup.component.html',
  styleUrls: ['./casino-setup.component.scss'],
})
export class CasinoSetupComponent implements OnInit {
  form: FormGroup;
  imageSrc = 'assets/images/logo-here.png';
  imgSize: boolean = false;
  imgType: boolean = false;


  CasinoPackageData: Array<any> = [
    { name: 'form.Everything', value: 'Everything' },
    { name: 'form.VirtualSports', value: 'Virtual Sport' },
    { name: 'form.SportsBetting', value: 'Sports Betting' },
    { name: 'form.Slot', value: 'Slots  / Table Games' },
    { name: 'form.LiveCasino', value: 'Live Casino' },
  ];


  paymentProcessingData: Array<any> = [
    { name: 'Coinpayments', value: 'Coinpayments' },
    { name: 'Coinspaid', value: 'Coinspaid' },
    { name: 'Wland', value: 'Wland' },
    { name: 'Moonpay', value: 'Moonpay' },
    { name: 'form.agentSystem', value: 'form.agentSystem' },
  ];


  constructor(private formBuilder: FormBuilder, public paymentsService: PaymentsService, private translateService: TranslateService) {
    this.buildForm();
  }

  ngOnInit(): void {
    const offer = document.getElementById('casino-setup');
    if (offer) {
      const header = document.getElementById('landing-setup');
      header.classList.add('--not-found');
      header.style.display = 'none';
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      domain: ['', Validators.required],
      logo: [''],
      logoImage: [''],
      gamingLicense: ['', Validators.required],
      gamingLicenseComments: [''],
      region: ['', Validators.required],
      casinoPackage: this.formBuilder.array([], Validators.required),
      casinoPackageComments: [''],
      multiPlayer: ['', Validators.required],
      paymentProcessing: this.formBuilder.array([], Validators.required),
      currency: ['', Validators.required],
      companySetup: ['', Validators.required],
      administrativeAssistant: ['', Validators.required],
      customPayment: ['', Validators.required],
      customPaymentComments: [''],
      filename: [''],
      filetype: ['', Validators.required],
    });
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log([file]);

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result as string;


        if ((file.type === 'image/png') || (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/svg')) {
          this.imgType = false;
        } else {
          this.imgType = true;
          return;
        }


        this.form.patchValue({
          filename: file.name,
          filetype: file.type,
          logoImage: this.imageSrc.split(',')[1]
        });

      };
    }
  }

  onCheckboxGamingLicense(e) {
    const checkArray: FormArray = this.form.get('gamingLicense') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onCheckboxCasinoPackage(e) {
    const checkArray: FormArray = this.form.get('casinoPackage') as FormArray;

    let virtualSports, sportBetting, liveCasino, slot, everything;

    everything = document.getElementById('Everything');
    virtualSports = document.getElementById('Virtual Sport');
    sportBetting = document.getElementById('Sports Betting');
    liveCasino = document.getElementById('Live Casino');
    slot = document.getElementById('Slots  / Table Games');

    if (e.target.id === 'Everything') {

      everything.classList.toggle("active");

      if (everything.classList.contains('active')) {
        virtualSports.checked = true;
        sportBetting.checked = true;
        liveCasino.checked = true;
        slot.checked = true;

        virtualSports.disabled = true;
        sportBetting.disabled = true;
        liveCasino.disabled = true;
        slot.disabled = true;

        checkArray.push(new FormControl(virtualSports.value));
        checkArray.push(new FormControl(sportBetting.value));
        checkArray.push(new FormControl(liveCasino.value));
        checkArray.push(new FormControl(slot.value));
      } else {
        virtualSports.checked = false;
        sportBetting.checked = false;
        liveCasino.checked = false;
        slot.checked = false;

        virtualSports.disabled = false;
        sportBetting.disabled = false;
        liveCasino.disabled = false;
        slot.disabled = false;

        checkArray.removeAt(virtualSports.value);
        checkArray.removeAt(sportBetting.value);
        checkArray.removeAt(liveCasino.value);
        checkArray.removeAt(slot.value);
      }

    }

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  openCommentLicense(e) {
    let comment = document.getElementById('iComment');

    if (e.target.id === 'yes-license') {
      comment.style.display = 'block';

    } else {
      comment.style.display = 'none';
    }
  }

  openCommentPayment(e) {
    let comment = document.getElementById('iPayment');
    console.log(e);

    if (e.target.id === 'yes-payment') {
      comment.style.display = 'block';

    } else {
      comment.style.display = 'none';
    }
  }

  onCheckboxPaymentProcessing(e) {
    const checkArray: FormArray = this.form.get('paymentProcessing') as FormArray;

    let coinpayment, coinspaid, moonpay, wland;

    coinpayment = document.getElementById('Coinpayments');
    coinspaid = document.getElementById('Coinspaid');
    moonpay = document.getElementById('Moonpay');
    wland = document.getElementById('Wland');

    if (e.target.id === 'Coinpayments') {
      coinpayment.classList.toggle("active");


      if (coinpayment.classList.contains('active')) {

        coinspaid.disabled = true;

      } else {
        coinspaid.disabled = false;
      }

    }

    if (e.target.id === 'Coinspaid') {

      coinspaid.classList.toggle("active");

      if (coinspaid.classList.contains('active')) {

        coinpayment.disabled = true;

      } else {
        coinpayment.disabled = false;
      }

    }


    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onFormSubmit() {
    if (this.form.invalid === true) {
      this.form.markAllAsTouched();
      return;
    } else {


      this.paymentsService.SendSetupForm(this.form.value);

    }
  }
}
