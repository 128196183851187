import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-frequent-questions",
  templateUrl: "./frequent-questions.component.html",
  styleUrls: ["./frequent-questions.component.scss"],
})
export class FrequentQuestionsComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FrequentQuestionsComponent>) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {}
}
